<div class="light-grey">
	<div class="row">
		<div class="col text-center title">{{'authentication' | translate}}</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-0 bg-white shadow-sm border-white rounded">
				<div class="card-body p-0 pb-2">
					<div class="block-background">
						<div class="block-info">
							<div class="row">
								<div class="col">Capture Failed</div>
							</div>
							<div class="row">
								<div class="col">Are you sure you would like to skip the capture process?</div>
							</div>
							<div class="row mt-2 p-2">
								<div class="d-grid gap-2 col-6 mx-auto space-after grid-button">
									<button (click)="tryAgain()" class="btn btn-zain btn-zain-next">Return to Capture Process</button>
								</div>
								<div class="d-grid gap-2 col-6 mx-auto space-after grid-button">
									<button (click)="skip()" class="btn btn-zain">Skip Capture Process</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
