export var Config = (function () {
    // -------------------------------------
    // REQUIRED
    // Available at https://dev.facetec.com/account
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    var DeviceKeyIdentifier = "dy14BZwdaWkXfnKEQ1g4viDEwOmKTMqP";

    // -------------------------------------
    // REQUIRED
    // The URL to call to process FaceTec SDK Sessions.
    // In Production, you likely will handle network requests elsewhere and without the use of this variable.
    // See https://dev.facetec.com/security-best-practices?link=facetec-server-rest-endpoint-security for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    // var BaseURL = "https://server.facetec.axonwireless.co.za/api/v3.1/biometrics";
    //Gman is the best. He made me remove a trailing forward slash and it fixed a CORS error.
    //Don't argue with Gman. He knows his shit. I don't know how or why, but he does.
    //Just shut up, and listen to him.
    var BaseURL = "https://server.facetec.axonwireless.co.za";

    // -------------------------------------
    // REQUIRED
    // The FaceMap Encryption Key you define for your application.
    // Please see https://dev.facetec.com/keys?link=keys for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    var PublicFaceScanEncryptionKey =
        "-----BEGIN PUBLIC KEY-----\n" +
        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5PxZ3DLj+zP6T6HFgzzk\n" +
        "M77LdzP3fojBoLasw7EfzvLMnJNUlyRb5m8e5QyyJxI+wRjsALHvFgLzGwxM8ehz\n" +
        "DqqBZed+f4w33GgQXFZOS4AOvyPbALgCYoLehigLAbbCNTkeY5RDcmmSI/sbp+s6\n" +
        "mAiAKKvCdIqe17bltZ/rfEoL3gPKEfLXeN549LTj3XBp0hvG4loQ6eC1E1tRzSkf\n" +
        "GJD4GIVvR+j12gXAaftj3ahfYxioBH7F7HQxzmWkwDyn3bqU54eaiB7f0ftsPpWM\n" +
        "ceUaqkL2DZUvgN0efEJjnWy5y1/Gkq5GGWCROI9XG/SwXJ30BbVUehTbVcD70+ZF\n" +
        "8QIDAQAB\n" +
        "-----END PUBLIC KEY-----";        
    // -------------------------------------
    // REQUIRED IF: You have had a Production Key generated for you.
    // Production Keys are generated for your account once all steps for Going Production have been completed.
    // More information on Going Production can be found at https://dev.facetec.com/going-production-overview.
    //
    // NOTE:  DO NOT hard code this into your actual App. This variable is coded into the App for demonstration purposes only.
    // Your App MUST serve down the key data via your own API. This allows you to change/update keys without making a client-side change.
    // Please see https://dev.facetec.com/licensing?link=integrating-production-licenses for more information.
    var ProductionKeyText = '{"domains": "axonwireless.co.za,bh.zain.com","expiryDate": "2024-12-10","key": "003044022046bfc1796f1f946dfd5d80e59b2070101c0657bf02b34ff0c8b112dfb510f83e0220342fbe6cd983ec901c645e66d394588e784ee5c7ab55c7aa118fc25c971bf480"}';

    // -------------------------------------
    // Convenience method to initialize the FaceTec SDK.
    // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
    function initializeFromAutogeneratedConfig(FaceTecSDK, callback) {
        console.log("Initializing With These Values: ", {
            DeviceKeyIdentifier: this.DeviceKeyIdentifier,
            PublicFaceScanEncryptionKey: this.PublicFaceScanEncryptionKey,
            BaseURL: this.BaseURL,
            ProductionKeyText: this.ProductionKeyText,
        });

        FaceTecSDK.initializeInProductionMode(
            this.ProductionKeyText,
            this.DeviceKeyIdentifier,
            this.PublicFaceScanEncryptionKey,
            function (initializedSuccessfully) {
                callback(initializedSuccessfully);
            },
        );
    }

    // This app can modify the customization to demonstrate different look/feel preferences
    // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
    function retrieveConfigurationWizardCustomization(FaceTecSDK) {
        var sdkImageDirectory = "assets/core-sdk/FaceTec_images/";

        // For Color Customization
        var outerBackgroundColor = "#f9f9f9";
        var frameColor = "#FFFFFF";
        var borderColor = "#ffffff";
        var ovalColor = "#6c757d";
        var dualSpinnerColor = "#00515e";
        var textColor = "#000000";
        var buttonAndFeedbackBarColor = "#009386";
        var buttonAndFeedbackBarTextColor = "#ffffff";
        var buttonColorHighlight = "#00515e";
        var buttonColorDisabled = "#dafbf8";
        var pink = "#e02bd4"; //for gman

        // For Frame Corner Radius Customization
        let frameCornerRadius = "20px";

        // For Cancel Button Customization
        var cancelButtonImage = sdkImageDirectory + "FaceTec_cancel.png";
        var cancelButtonLocation = FaceTecSDK.FaceTecCancelButtonLocation.TopRight;

        // For image Customization
        var yourAppLogoImage = sdkImageDirectory + "FaceTec_your_app_logo.png";
        var securityWatermarkImage = FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec;

        // Set a default customization
        var defaultCustomization = new FaceTecSDK.FaceTecCustomization();

        // Set Frame Customization
        defaultCustomization.frameCustomization.borderCornerRadius = frameCornerRadius;
        defaultCustomization.frameCustomization.backgroundColor = frameColor;
        defaultCustomization.frameCustomization.borderColor = borderColor;

        // Set Overlay Customization
        defaultCustomization.overlayCustomization.showBrandingImage = true;
        defaultCustomization.overlayCustomization.brandingImage = yourAppLogoImage;
        defaultCustomization.overlayCustomization.backgroundColor = outerBackgroundColor;

        // Set Guidance Customization
        defaultCustomization.guidanceCustomization.backgroundColors = frameColor;
        defaultCustomization.guidanceCustomization.foregroundColor = textColor;
        defaultCustomization.guidanceCustomization.buttonBackgroundNormalColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.guidanceCustomization.buttonBackgroundDisabledColor =
            buttonColorDisabled;
        defaultCustomization.guidanceCustomization.buttonBackgroundHighlightColor =
            buttonColorHighlight;
        defaultCustomization.guidanceCustomization.buttonTextNormalColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.buttonTextDisabledColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.buttonTextHighlightColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.retryScreenImageBorderColor = borderColor;
        defaultCustomization.guidanceCustomization.retryScreenOvalStrokeColor = borderColor;

        // Set Oval Customization
        defaultCustomization.ovalCustomization.strokeColor = ovalColor;
        defaultCustomization.ovalCustomization.progressColor1 = dualSpinnerColor;
        defaultCustomization.ovalCustomization.progressColor2 = dualSpinnerColor;

        // Set Feedback Customization
        defaultCustomization.feedbackCustomization.backgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.feedbackCustomization.textColor = buttonAndFeedbackBarTextColor;

        // Set Cancel Customization
        defaultCustomization.cancelButtonCustomization.customImage = cancelButtonImage;
        defaultCustomization.cancelButtonCustomization.location = cancelButtonLocation;

        // Set Security Watermark Customization
        defaultCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(
            securityWatermarkImage,
        );

        // Set Result Screen Customization
        defaultCustomization.resultScreenCustomization.backgroundColors = frameColor;
        defaultCustomization.resultScreenCustomization.foregroundColor = textColor;
        defaultCustomization.resultScreenCustomization.activityIndicatorColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.resultScreenCustomization.resultAnimationBackgroundColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.resultScreenCustomization.resultAnimationForegroundColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.resultScreenCustomization.uploadProgressFillColor =
            buttonAndFeedbackBarColor;

        // Set ID Scan Customization
        defaultCustomization.idScanCustomization.selectionScreenBackgroundColors = frameColor;
        defaultCustomization.idScanCustomization.selectionScreenForegroundColor = textColor;

        defaultCustomization.idScanCustomization.reviewScreenBackgroundColors = frameColor;
        defaultCustomization.idScanCustomization.reviewScreenForegroundColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.reviewScreenTextBackgroundColor = textColor;
        defaultCustomization.idScanCustomization.reviewScreenTextBackgroundColor = frameColor;
        defaultCustomization.idScanCustomization.reviewScreenTextBackgroundBorderColor =
            borderColor;
        defaultCustomization.idScanCustomization.reviewScreenTextBackgroundBorderWidth = "2px";
        defaultCustomization.idScanCustomization.reviewScreenTextBackgroundBorderCornerRadius =
            "5px";

        defaultCustomization.ocrConfirmationCustomization.mainHeaderTextColor = textColor;
        defaultCustomization.ocrConfirmationCustomization.sectionHeaderTextColor = textColor;
        defaultCustomization.ocrConfirmationCustomization.scrollIndicatorBackgroundNormalColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.ocrConfirmationCustomization.scrollIndicatorBackgroundHighlightColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.ocrConfirmationCustomization.scrollIndicatorForegroundNormalColor =
            frameColor;
        defaultCustomization.ocrConfirmationCustomization.scrollIndicatorForegroundHighlightColor =
            frameColor;
        defaultCustomization.ocrConfirmationCustomization.scrollIndicatorBorderColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.ocrConfirmationCustomization.scrollIndicatorBorderWidth = "2px";
        defaultCustomization.ocrConfirmationCustomization.scrollIndicatorCornerRadius = "-1";

        defaultCustomization.ocrConfirmationCustomization.buttonTextNormalColor = frameColor;
        defaultCustomization.ocrConfirmationCustomization.buttonBackgroundNormalColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.ocrConfirmationCustomization.buttonTextHighlightColor = frameColor;
        defaultCustomization.ocrConfirmationCustomization.buttonBackgroundHighlightColor =
            "rgb(86, 86, 86)";
        defaultCustomization.ocrConfirmationCustomization.buttonTextDisabledColor = frameColor;
        defaultCustomization.ocrConfirmationCustomization.buttonBackgroundDisabledColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.ocrConfirmationCustomization.buttonBorderColor = "transparent";
        defaultCustomization.ocrConfirmationCustomization.buttonBorderWidth = "0px";
        defaultCustomization.ocrConfirmationCustomization.buttonCornerRadius = "20px";
        // defaultCustomization.ocrConfirmationCustomization.scrollIndicatorFont = font;
        // defaultCustomization.ocrConfirmationCustomization.scrollIndicatorShadow = shadow;

        defaultCustomization.idScanCustomization.captureScreenForegroundColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.captureScreenTextBackgroundColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.buttonBackgroundNormalColor =
            buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.buttonBackgroundDisabledColor =
            buttonColorDisabled;
        defaultCustomization.idScanCustomization.buttonBackgroundHighlightColor =
            buttonColorHighlight;
        defaultCustomization.idScanCustomization.buttonTextNormalColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.buttonTextDisabledColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.buttonTextHighlightColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.captureScreenBackgroundColor = frameColor;
        defaultCustomization.idScanCustomization.captureFrameStrokeColor = borderColor;

        // Set Initial Loading Customization
        defaultCustomization.initialLoadingAnimationCustomization.backgroundColor =
            buttonAndFeedbackBarTextColor;
        defaultCustomization.initialLoadingAnimationCustomization.foregroundColor =
            buttonAndFeedbackBarColor;

        return defaultCustomization;
    }

    function retrieveLowLightConfigurationWizardCustomization(FaceTecSDK) {
        var defaultCustomization = new FaceTecSDK.FaceTecCustomization();
        this.currentLowLightCustomization = defaultCustomization;
        return defaultCustomization;
    }

    function retrieveDynamicDimmingConfigurationWizardCustomization(FaceTecSDK) {
        // var defaultCustomization = new FaceTecSDK.FaceTecCustomization();
        // this.currentDynamicDimmingCustomization = defaultCustomization;
        // return defaultCustomization;
        // For Color Customization
        var textColor = "#ffffff";

        // Set a default customization
        var dynamicDimmingCustomization = retrieveConfigurationWizardCustomization(FaceTecSDK);

        // Set Guidance Customization
        dynamicDimmingCustomization.guidanceCustomization.foregroundColor = textColor;

        // Set Result Screen Customization
        dynamicDimmingCustomization.resultScreenCustomization.foregroundColor = textColor;

        // Set ID Scan Customization
        dynamicDimmingCustomization.idScanCustomization.selectionScreenForegroundColor = textColor;

        return dynamicDimmingCustomization;
    }

    var currentCustomization;
    var currentLowLightCustomization;
    var currentDynamicDimmingCustomization;

    // -------------------------------------
    // Boolean to indicate the FaceTec SDK Configuration Wizard was used to generate this file.
    // In this Sample App, if this variable is true, a "Config Wizard Theme" will be added to this App's "Toggle Colors & Themes" menu,
    // and choosing this option will set the FaceTec SDK UI/UX Customizations to the Customizations that you selected in the
    // Configuration Wizard.
    var wasSDKConfiguredWithConfigWizard = true;

    return {
        wasSDKConfiguredWithConfigWizard,
        DeviceKeyIdentifier,
        BaseURL,
        PublicFaceScanEncryptionKey,
        ProductionKeyText,
        initializeFromAutogeneratedConfig,
        currentCustomization,
        currentLowLightCustomization,
        currentDynamicDimmingCustomization,
        retrieveConfigurationWizardCustomization,
        retrieveLowLightConfigurationWizardCustomization,
        retrieveDynamicDimmingConfigurationWizardCustomization,
    };
})();
