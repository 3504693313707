<div class="light-grey">
	<div class="row">
		<div class="col text-center">{{'authentication' | translate}}</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-0 bg-white shadow-sm border-white rounded">
				<div class="card-body p-0 pb-2">
					<div class="block-background">
						<div class="block-info">
							{{status}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>