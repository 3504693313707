import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  portraitEvent = new Subject<any>();
  idResultEvent = new Subject<any>();

  emitPortraitEvent(data: any) {
    this.portraitEvent.next(data);
  }

  getPortraitEvent() {
    return this.portraitEvent.asObservable();
  }

  emitIdResultEvent(data: any) {
    this.idResultEvent.next(data);
  }

  getIdResultEvent() {
    return this.idResultEvent.asObservable();
  }
}