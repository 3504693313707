import { PhotoIDNextStepEnum, STRING_MATCH_MIN_SCORE } from "../constants";
import { Config, Data, FaceTecResponse, ImageTypeEnum } from "../modules/facetec/dto";
import { ConfigService } from "../service/config.service";
import { DataService } from "../service/data.service";
import { HttpFacetecService } from "../service/http-facetec.service";

// function calculateMatchScore(str1: string, str2: string): number {
//     return 100;
// }

function calculateMatchScore(string1: string, string2: string): number {
    const normalizedStr1 = normalizeIdNumber(string1);
    const normalizedStr2 = normalizeIdNumber(string2);

    console.log("Calculating Match Score: ", { string1, string2, normalizedStr1, normalizedStr2 });
    let score = 0;
    // Case 1: Exact match
    if (normalizedStr1 === normalizedStr2) {
        score = 100;
    }

    // Find the number of matching characters at the beginning
    const minLength = Math.min(normalizedStr1.length, normalizedStr2.length);
    let matchingChars = 0;
    for (let i = 0; i < minLength; i++) {
        if (normalizedStr1[i] === normalizedStr2[i]) {
            matchingChars++;
        } else {
            break;
        }
    }

    // Calculate the score based on the fraction of matching characters
    if (matchingChars > 0) {
        const longerLength = Math.max(normalizedStr1.length, normalizedStr2.length);
        score = (matchingChars / longerLength) * 100;
    }

    console.log("Match Score: ", { normalizedStr1, normalizedStr2, score });
    return score;
}

const setIdGroupName = (config: Config, data: Data) => {
    console.log("Setting ID Group Name: ", { config, data });
    var idGroupName = findIdGroupName(config, data.barcodeTemplateType, data.barcodeTemplateName);
    if (!idGroupName) {
        idGroupName = findIdGroupName(config, data.ocrTemplateType, data.ocrTemplateName);
    }

    data.idGroupName = idGroupName;
};

const findIdGroupName = (config: Config, templateType: string, templateName: string): string => {
    console.log("Finding ID Group Name: ", { templateType, templateName, config });
    if (!config.allowedIdTypes) {
        return undefined;
    }

    if (!templateType || !templateName) {
        return undefined;
    }

    var idGroupName: string;
    // try match on template name first
    config.allowedIdTypes.forEach((allowedIdTypeGroup) => {
        if (allowedIdTypeGroup.templateNames) {
            if (allowedIdTypeGroup.templateNames.filter((item) => item === templateName)[0]) {
                idGroupName = allowedIdTypeGroup.groupName;
            }
        }
    });

    if (idGroupName) {
        return idGroupName;
    }

    //if no match, then attempt to match on templateType
    config.allowedIdTypes.forEach((allowedIdTypeGroup) => {
        if (allowedIdTypeGroup.templateTypes) {
            if (allowedIdTypeGroup.templateTypes.filter((item) => item === templateType)[0]) {
                idGroupName = allowedIdTypeGroup.groupName;
            }
        }
    });

    return idGroupName;
};

const extractDate = (value: string, format: string) => {
    if (!format) {
        return value;
    }

    if (format === "dd MMM/MMM yyyy") {
        let indexSlash = value.indexOf("/");
        return value.slice(0, indexSlash) + value.slice(indexSlash + 4);
    }

    return value;
};

const processDocumentData = ({ data, result }: { data: Data; result: FaceTecResponse }) => {
    if (!result.documentData) {
        return;
    }

    data.barcodeTemplateName = result.documentData.templateInfo?.templateName;
    data.barcodeTemplateType = result.documentData.templateInfo?.templateType;
};

const processOcrData = ({ data, result }: { data: Data; result: FaceTecResponse }) => {
    if (!result.ocrResults) {
        return;
    }

    data.ocrTemplateName = result.ocrResults.ocrResults?.templateName;
    data.ocrTemplateType = result.ocrResults.ocrResults?.templateType;
};

export const normalizeIdNumber = (idNumber: string): string => {

    console.log("Normalizing ID Number: ", { idNumber });

    const replaceLetters = idNumber
        .toUpperCase()
        .replace(/O/g, "0")
        .replace(/I/g, "1")
        .replace(/L/g, "1") // Really catered for i being l.
        .replace(/V/g, "N")
        .replace(/ /g, "");

    return replaceLetters;

};

export const checkIdMatch = ({
    result,
    data,
    frontData,
    config,
}: {
    result: FaceTecResponse;
    data: Data;
    frontData: Data;
    config: Config;
}) => {
    try {
        console.log("Checking ID Match")
        if (config.disableMismatchCheck) {
            console.log("ID Mismatch Disabled. Not Checking ID Match");
            return;
        }

        result.axonMismatchInformation = false;

        if (!frontData || !frontData.idNumber) {
            // frontData.idNumber = data.axonExtractedData.idNumber;
            frontData.idNumber = "";
        }

        console.log("FD/BD", frontData.idNumber, data.axonExtractedData.idNumber);

        const score = calculateMatchScore(data.axonExtractedData.idNumber, frontData.idNumber);

        data.idMatchScore = score;
        data.idPartialMatch = score >= STRING_MATCH_MIN_SCORE && score < 100;
        result.axonMismatchInformation = false;

        if (score < STRING_MATCH_MIN_SCORE) {
            result.axonMismatchInformation = true;
            result.mismatchedData["idNumber"] = {
                current: data.axonExtractedData.idNumber,
                previous: frontData.idNumber,
            };
        }
    } catch (e) {
        console.log("Error Checking ID Match: ", e);
    }
};

export const processPhotos = ({ result, data }: { result: FaceTecResponse; data: Data }) => {
    var idPhotoFront = data.imageList.filter((item) => item.type === ImageTypeEnum.IdPhotoFront)[0];
    if (result.photoIDFrontCrop) {
        if (idPhotoFront) {
            idPhotoFront.image = result.photoIDFrontCrop;
        } else {
            data.imageList.push({
                image: result.photoIDFrontCrop,
                type: ImageTypeEnum.IdPhotoFront,
            });
        }
    }

    var idPhotoBack = data.imageList.filter((item) => item.type === ImageTypeEnum.IdPhotoBack)[0];
    if (result.photoIDBackCrop) {
        if (idPhotoBack) {
            idPhotoBack.image = result.photoIDBackCrop;
        } else {
            data.imageList.push({
                image: result.photoIDBackCrop,
                type: ImageTypeEnum.IdPhotoBack,
            });
        }
    }

    var idPhotoFace = data.imageList.filter((item) => item.type === ImageTypeEnum.IdPhotoFace)[0];
    if (result.photoIDFaceCrop) {
        if (idPhotoFace) {
            idPhotoFace.image = result.photoIDFaceCrop;
        } else {
            data.imageList.push({
                image: result.photoIDFaceCrop,
                type: ImageTypeEnum.IdPhotoFace,
            });
        }
    }

    var idSignature = data.imageList.filter((item) => item.type === ImageTypeEnum.IdSignature)[0];
    if (result.photoIDPrimarySignatureCrop) {
        if (idSignature) {
            idSignature.image = result.photoIDPrimarySignatureCrop;
        } else {
            data.imageList.push({
                image: result.photoIDPrimarySignatureCrop,
                type: ImageTypeEnum.IdSignature,
            });
        }
    }

    var tamperFront = data.imageList.filter(
        (item) => item.type === ImageTypeEnum.TamperingFrontEvidence,
    )[0];
    if (result.photoIDTamperingEvidenceFrontImage) {
        if (tamperFront) {
            tamperFront.image = result.photoIDTamperingEvidenceFrontImage;
        } else {
            data.imageList.push({
                image: result.photoIDTamperingEvidenceFrontImage,
                type: ImageTypeEnum.TamperingFrontEvidence,
            });
        }
    }

    var tamperBack = data.imageList.filter(
        (item) => item.type === ImageTypeEnum.TamperingBackEvidence,
    )[0];
    if (result.photoIDTamperingEvidenceBackImage) {
        if (tamperBack) {
            tamperBack.image = result.photoIDTamperingEvidenceBackImage;
        } else {
            data.imageList.push({
                image: result.photoIDTamperingEvidenceBackImage,
                type: ImageTypeEnum.TamperingBackEvidence,
            });
        }
    }

    data.idPhotoMatchLevel = result.matchLevel;
    data.ageEstimate = result.idScanAgeEstimateGroupV2EnumInt; //could also be result.idScanAgeV2GroupEnumInt (who the fuck knows)
};

export const processIdResult = ({
    result,
    dataService,
    configService,
    handleErrors,
    proceed,
}: {
    result: FaceTecResponse;
    dataService: DataService;
    configService: ConfigService;
    handleErrors: (res: FaceTecResponse) => boolean;
    proceed: () => void;
}) => {
    console.log("Processing Id Result: ", {
        didMatchIDScanToOCRTemplate: result.didMatchIDScanToOCRTemplate,
        didCompleteIDScanWithoutMatching: result.didCompleteIDScanWithoutMatching,
        photoIDNextStepEnumInt: result.photoIDNextStepEnumInt,
        matchLevel: result.matchLevel,
        minMatchLevel: configService.getConfig().minMatchLevel,
        digitalIDSpoofStatusEnumInt: result.digitalIDSpoofStatusEnumInt,
        compareDISSE: result.digitalIDSpoofStatusEnumInt === 1,
        isCompletelyDone: result.isCompletelyDone,
        isFront: result.photoIDNextStepEnumInt === PhotoIDNextStepEnum.FRONT_RETRY,
        isBack: result.photoIDNextStepEnumInt === PhotoIDNextStepEnum.BACK,
        retryCounter: configService.getRetryCounter(),
        maxRetries: configService.getConfig().maxFailedRetries,
    })

    const data = dataService.getData();
    const frontData = dataService.getFrontData();
    data.axonExtractedData = result.axonExtractedData;

    const config = configService.getConfig();
    result.axonMismatchInformation = false;
    result.mismatchedData = {};
    result.missingData = "";

    if (result.axonExtractedData) {
        data.firstName = result.axonExtractedData.firstName;
        data.lastName = result.axonExtractedData.lastName;
        data.fullName = result.axonExtractedData.fullName;
        data.nationality = result.axonExtractedData.nationality;
        data.dateOfBirth = result.axonExtractedData.dateOfBirth;
        data.idNumber = result.axonExtractedData.idNumber;
        data.countryCode = result.axonExtractedData.countryCode;
        data.dateOfExpiration = result.axonExtractedData.dateOfExpiration;
        data.sex = result.axonExtractedData.sex;
        data.nationalityNonMRZValue = result.axonExtractedData.nationalityNonMRZValue;
        data.placeOfBirth = result.axonExtractedData.placeOfBirth;
        data.idNumber2 = result.axonExtractedData.idNumber2;
        data.idBarcode = result.axonExtractedData.idBarcode;
        data.dateOfIssue = result.axonExtractedData.dateOfIssue;
        data.address = result.axonExtractedData.address;
        data.countryCodeNonMRZValue = result.axonExtractedData.countryCodeNonMRZValue;

        // others

        dataService.setData(data);
    }

    if (result.didMatchIDScanToOCRTemplate === true) {
        if (result.documentData) {
            console.log("Processing Document Data")
            processDocumentData({ data, result });
        }

        if (result.ocrResults) {
            console.log("Processing Ocr Data")
            processOcrData({ data, result });
        }

        if (result.photoIDNextStepEnumInt === PhotoIDNextStepEnum.BACK) {
            dataService.setFrontData(data.axonExtractedData);
        }

        if (result.photoIDNextStepEnumInt === PhotoIDNextStepEnum.USER_CONFIRM || result.photoIDNextStepEnumInt === PhotoIDNextStepEnum.COMPLETE) {
            checkIdMatch({ result, frontData, data, config });
        }
    }

    // process the photos if any
    processPhotos({ data, result });

    if (result.additionalSessionData) {
        data.browser = result.additionalSessionData.userAgent;
        data.deviceModel = result.additionalSessionData.deviceModel;
        data.deviceSDK = result.additionalSessionData.deviceSDKVersion;
        data.platform = result.additionalSessionData.platform;
        data.ipAddress = result.additionalSessionData.ipAddress;
    }

    setIdGroupName(config, data);

    console.log("Setting Data: ", data);
    dataService.setData(data);

    if (configService.getRetryCounter() > config.maxFailedRetries) {
        handleErrors({ didCompleteIDScanWithoutMatching: true } as FaceTecResponse);
        return;
    }

    if (!result.isCompletelyDone) {
        return;
    }

    console.log("Checking ID Spook Check: ", {
        digitalIDSpoofStatusEnumInt: result.digitalIDSpoofStatusEnumInt,
        disableIdSpoofCheck: config.disableIdSpoofCheck
    });

    if (result.digitalIDSpoofStatusEnumInt === 1 && !config.disableIdSpoofCheck) {
        console.log("Handling Error Digital ID Spoof Detected:", result.digitalIDSpoofStatusEnumInt);
        handleErrors({ ...result, digitalIDSpoofDetected: true });
        return;
    }

    if (result.axonMismatchInformation) {
        console.log("Handling Error MisMatch Information:", result.axonMismatchInformation);
        handleErrors({ ...result, axonMismatchInformation: true });
        return;
    }

    if (result.matchLevel < configService.getConfig().minMatchLevel) {
        console.log("Handling Error Match Failed:", result.matchLevel);
        handleErrors({ ...result, didCompleteIDScanWithoutMatching: true });
        return;
    }

    if (
        result.isCompletelyDone === true &&
        result.matchLevel >= configService.getConfig().minMatchLevel
    ) {
        console.log("Success What Data We Have: ", {
            fullName: data.fullName,
            dateOfBirth: data.dateOfBirth,
            nationality: data.nationality,
            countryCode: data.countryCode,
            idNumber: data.idNumber,
            expiryDate: data.dateOfExpiration,
        })
        proceed();
        return;
    } else {
        console.log("Handling Error Match Failed: ", result.isCompletelyDone, result.matchLevel, result.axonMissingInformation);
        handleErrors({ ...result, didCompleteIDScanWithoutMatching: true });
        return;
    }
};

export const processPortraitResult = (result: any, dataService: DataService) => {
    const data = dataService.getData();
    // console.log(data);

    var portrait = data.imageList.filter((item) => item.type === ImageTypeEnum.Portrait)[0];
    if (portrait) {
        portrait.image = result;
        return;
    }
    data.imageList.push({
        image: result,
        type: ImageTypeEnum.Portrait,
    });

    dataService.setData(data);
};

export const trackJourney = ({
    ref,
    page,
    httpService,
}: {
    ref: string;
    page: string;
    httpService: HttpFacetecService;
}) => {
    httpService.trackJourney(ref, page).subscribe((_data) => {
        console.log(`${page} tracked`);
    });
};


export const safeStringify = (obj) => {
    console.info("Safe Stringify: ", obj);
    const seen = new WeakSet();
    return JSON.stringify(obj, (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return; // Discard circular reference
            }
            seen.add(value);
        }
        return value;
    });
}
