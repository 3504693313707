
<div class="container-fluid">
	<div class="row">
		<main role="main" class="col">
			<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-clip-rotate-multiple">
				<p style="font-size: 20px; color: white">Loading...</p>
			</ngx-spinner>
			<router-outlet></router-outlet>
		</main>
		<!--Spacer for footer version-->
		<div>&nbsp;</div>
	</div>
	<div class="fixed-bottom">
		
		
	</div>
</div>

<!-- <ng-template #content let-offcanvas>
	<div class="offcanvas-header">
		<h4 class="offcanvas-title" id="offcanvas-basic-title">Menu</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
	</div>
	<div class="offcanvas-body">
		<ng-container *ngIf="isEnabled('Kiosk')">
			<h6
				class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
				<span>Kiosk</span>
			</h6>
			<ul class="nav flex-column mb-2">
				<li class="nav-item">
					<a [routerLink]="['/kiosk','list']" [routerLinkActive]="['active']" class="nav-link" (click)="offcanvas.dismiss('Cross click')">
						<fa-icon [icon]="faLayerGroup"></fa-icon>
						All Kiosks
					</a>
				</li>
				<li class="nav-item">
					<a [routerLink]="['/kiosk','problems']" [routerLinkActive]="['active']" class="nav-link" (click)="offcanvas.dismiss('Cross click')">
						<fa-icon [icon]="problem"></fa-icon>
						Problem Kiosks
					</a>
				</li>
			</ul>
			<h6
				class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
				<span>Admin</span>
			</h6>
			<ul class="nav flex-column mb-2">
				<li class="nav-item">
					<a [routerLink]="['/kiosk','admins']" [routerLinkActive]="['active']" class="nav-link" (click)="offcanvas.dismiss('Cross click')">
						<fa-icon [icon]="allAdmins"></fa-icon>
						All Admins
					</a>
				</li>
				<li class="nav-item">
					<a [routerLink]="['/kiosk','add-admin']" [routerLinkActive]="['active']" class="nav-link" (click)="offcanvas.dismiss('Cross click')">
						<fa-icon [icon]="addAdmin"></fa-icon>
						Add Admin
					</a>
				</li>
			</ul>
		</ng-container>
	</div>
</ng-template> -->