import { Component, OnDestroy, OnInit } from "@angular/core";
import { Ref } from "../../../dto";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import { catchError } from "rxjs/operators";
import { FaceTecSDKService } from "src/app/service/FaceTecSDKService";
import { ConfigService } from "src/app/service/config.service";
import { RefService } from "src/app/service/ref.service";
import { Config as FacetecConfig } from "../../../../../../assets/Config";
import { trackJourney } from "src/app/utils/utils";
import { MRZDecoderService } from "src/app/service/mrz.decode.server";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

// TODO: Remove all instances of instant nd convert to get
@Component({
    selector: "app-landing",
    templateUrl: "./landing.component.html",
    styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit, OnDestroy {
    status: string;
    sdkInitialised = true;

    private langChangeSubscription: Subscription;
    constructor(
        private activatedRoute: ActivatedRoute,
        private httpService: HttpFacetecService,
        private facetecSDKService: FaceTecSDKService,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private configService: ConfigService,
        private refService: RefService,
        private router: Router,
        private translateService: TranslateService,
        private mrzService: MRZDecoderService,
    ) {
        console.log("LandingComponent constructor");
    }

    ngOnDestroy() {
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    async initTranslations() {
        this.status = await this.translateService.get("invalid_reference_provided").toPromise();
    }

    async ngOnInit() {
        this.initTranslations();

        this.langChangeSubscription = this.translateService.onLangChange.subscribe(event => {
            this.initTranslations();
        });        
        
        var ref: string = null;
        this.activatedRoute.queryParamMap.subscribe((params) => {
            ref = params.get("ref");
        });

        if (!ref) {
            this.toastr.error(await this.translateService.get("invalid_reference_provided").toPromise(), await this.translateService.get("error").toPromise());
            this.status = await this.translateService.get("invalid_reference_provided").toPromise();
            return;
        }

        trackJourney({
            ref: ref,
            page: "landing_validate_ref_start",
            httpService: this.httpService,
        });

        var reference: Ref = {
            ref: ref,
        };

        this.spinner.show();
        this.httpService
            .validateRef(reference)
            .pipe(
                catchError(async (error) => {
                    this.spinner.hide();
                    console.log("Error validating reference", error);
                    this.toastr.error(error.statusText ? error.statusText : error.message, await this.translateService.get("error").toPromise());
                    this.status =
                        await this.translateService.get("error_occured_validating_reference").toPromise() + " " +
                        (error.statusText ? error.statusText : error.message);
                    throw error;
                }),
            )
            .subscribe(async (result) => {
                this.spinner.hide();
                if (!result) {
                    this.toastr.error(await this.translateService.get("error_occured_validating_reference").toPromise(), await this.translateService.get("error").toPromise());
                    this.status = await this.translateService.get("error_occured_validating_reference").toPromise();
                    return;
                }

                if (!result.status) {
                    this.toastr.error(await this.translateService.get("error_occured_validating_reference").toPromise() + " " + result.error, await this.translateService.get("error").toPromise());
                    this.status = await this.translateService.get("error_occured_validating_reference").toPromise() + " " + result.error;
                    return;
                }

                trackJourney({
                    ref: ref,
                    page: "landing_validate_ref_success",
                    httpService: this.httpService,
                });
                this.configService.setConfig(result.config);
                this.refService.setRef(ref);

                const config = this.configService.getConfig();

                FacetecConfig.BaseURL = config.url;
                console.log("URL set to [" + FacetecConfig.BaseURL + "]");

                if (config.deviceKeyIdentifier) {
                    FacetecConfig.DeviceKeyIdentifier = config.deviceKeyIdentifier;
                    console.log("Device Key Identifier set...");
                }
                if (config.publicFaceScanEncryptionKey) {
                    FacetecConfig.PublicFaceScanEncryptionKey = config.publicFaceScanEncryptionKey;
                    console.log("Public Face Scan Encryption Key set...");
                }
                if (config.productionKeyText) {
                    FacetecConfig.ProductionKeyText = config.browserSdkLicense;
                    console.log("Production Key Text set...");
                }

                trackJourney({
                    ref: ref,
                    page: "sdk_initialize_browser_sdk_start",
                    httpService: this.httpService,
                });
                this.facetecSDKService.initializeBrowserSDK({
                    onInitializeFailed: this.onInitializeFailed.bind(this),
                    onInitializeSuccess: this.onInitializeSuccess.bind(this),
                });
            });
    }

    onInitializeSuccess() {
        trackJourney({
            ref: this.refService.getRef(),
            page: "sdk_initialize_browser_sdk_success",
            httpService: this.httpService,
        });
        console.log("onInitializeSuccess");
        this.sdkInitialised = true;
        this.start();
    }

    onInitializeFailed() {
        this.sdkInitialised = false;
    }

    start() {
        this.configService.setTryAgain(false);
        this.router.navigate(["/facetec"]);
    }
}
