import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { getLocalizedStrings } from "./constants";
import { Console } from "console";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	constructor(private translateService: TranslateService, private activatedRoute: ActivatedRoute) {
		this.translateService.addLangs(["ar", "en"]);
	}

	ngOnInit() {
	}

	ngOnDestroy(): void {
		this.translateService.onLangChange.unsubscribe();
	}
}
