import { Component, EventEmitter, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Config } from "../../../dto";
import { RefService } from "src/app/service/ref.service";
import { ConfigService } from "src/app/service/config.service";

@Component({
    selector: "app-takephoto",
    templateUrl: "./takephoto.component.html",
    styleUrls: ["./takephoto.component.scss"],
})
export class TakePhotoComponent {
    ref: string;
    config: Config;

    constructor(
        private router: Router,
        private zone: NgZone,
        private refService: RefService,
        private configService: ConfigService,
    ) {
        this.ref = this.refService.getRef();
        this.config = this.configService.getConfig() as Config;
    }

    proceed() {
        this.configService.setTryAgain(true);
        this.zone.run(() => {
            this.router.navigate(["/facetec"]);
        });
    }
    cancel() {
		this.configService.setTryAgain(false);
        this.router.navigate(["/facetec"]);
    }
}
