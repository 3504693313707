import { Injectable } from "@angular/core";
import { AllowedIdType, Config } from "../modules/facetec/dto";

@Injectable({ providedIn: "root" })
export class UtilService {
    static getAllowedIdTypes(config: Config): string {
        var out = "";
        var index = 1;
        const allowedIdTypes = [
            {
                groupName: "all_passports",
                templateNames: ["Passport"],
            },
            {
                groupName: "Bahrain National ID (CPR)",
                templateNames: ["CPR"],
            },
            {
                groupName: "GCC ID",
                templateNames: ["GCC ID"],
            }
        ]

        allowedIdTypes.forEach((allowedIdTypeGroup) => {
            out += index + ". " + "{{"+ allowedIdTypeGroup.groupName + " | translate}}" + "</br>";
            // out = UtilService.buildAllowedIdTypesMessage(allowedIdTypeGroup, out, index);
            index++;
        });

        return out;
    }

    private static buildAllowedIdTypesMessage(
        allowedIdTypeGroup: AllowedIdType,
        out: string,
        index: number,
    ): string {
        if (allowedIdTypeGroup.groupName === "Passport") {
            out += index + ". " + allowedIdTypeGroup.groupName + "</br></br>";
            return out;
        }

        out += index + ". " + allowedIdTypeGroup.groupName + ":";
        out += "</br>";
        if (allowedIdTypeGroup.templateNames) {
            allowedIdTypeGroup.templateNames.forEach((item) => (out += item + "</br>"));
        } else if (allowedIdTypeGroup.templateTypes) {
            allowedIdTypeGroup.templateTypes.forEach((item) => (out += item + "</br>"));
        }
        out += "</br>";
        return out;
    }
}
