import { Injectable } from "@angular/core";
import { Config } from "protractor";

@Injectable({
    providedIn: "root",
})
export class SessionService {
    session: string | null = null;

    setSession(session: string) {
        this.session = session;
    }

    getSession(): string | null {
        return this.session;
    }
}
