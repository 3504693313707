import { MAX_RETRIES, PhotoIDNextStepEnum, STRING_MATCH_MIN_SCORE } from "../constants";
import { Config, Data, FaceTecResponse, ImageTypeEnum } from "../modules/facetec/dto";
import { ConfigService } from "../service/config.service";
import { DataService } from "../service/data.service";
import { HttpFacetecService } from "../service/http-facetec.service";
import { DecodedType, MRZDecoderService, MRZType } from "../service/mrz.decode.server";

function calculateMatchScore(str1: string, str2: string): number {
    // Case 1: Exact match
    if (str1 === str2) {
        return 100;
    }

    // Find the number of matching characters at the beginning
    const minLength = Math.min(str1.length, str2.length);
    let matchingChars = 0;
    for (let i = 0; i < minLength; i++) {
        if (str1[i] === str2[i]) {
            matchingChars++;
        } else {
            break;
        }
    }

    // Calculate the score based on the fraction of matching characters
    if (matchingChars > 0) {
        const longerLength = Math.max(str1.length, str2.length);
        return (matchingChars / longerLength) * 100;
    }

    // Case 3: No match
    return 0;
}

const setIdGroupName = (config: Config, data: Data) => {
    var idGroupName = findIdGroupName(config, data.barcodeTemplateType, data.barcodeTemplateName);
    if (!idGroupName) {
        idGroupName = findIdGroupName(config, data.ocrTemplateType, data.ocrTemplateName);
    }

    data.idGroupName = idGroupName;
};

const findIdGroupName = (config: Config, templateType: string, templateName: string): string => {
    if (!config.allowedIdTypes) {
        return undefined;
    }

    if (!templateType || !templateName) {
        return undefined;
    }

    var idGroupName: string;
    // try match on template name first
    config.allowedIdTypes.forEach((allowedIdTypeGroup) => {
        if (allowedIdTypeGroup.templateNames) {
            if (allowedIdTypeGroup.templateNames.filter((item) => item === templateName)[0]) {
                idGroupName = allowedIdTypeGroup.groupName;
            }
        }
    });

    if (idGroupName) {
        return idGroupName;
    }

    //if no match, then attempt to match on templateType
    config.allowedIdTypes.forEach((allowedIdTypeGroup) => {
        if (allowedIdTypeGroup.templateTypes) {
            if (allowedIdTypeGroup.templateTypes.filter((item) => item === templateType)[0]) {
                idGroupName = allowedIdTypeGroup.groupName;
            }
        }
    });

    return idGroupName;
};

const extractDate = (value: string, format: string) => {
    if (!format) {
        return value;
    }

    if (format === "dd MMM/MMM yyyy") {
        let indexSlash = value.indexOf("/");
        return value.slice(0, indexSlash) + value.slice(indexSlash + 4);
    }

    return value;
};

const processUserInfoFields = ({
    userInfo,
    data,
    result,
}: {
    data: Data;
    result: FaceTecResponse;
    userInfo: {
        fields: Array<{
            fieldKey: string;
            uiFieldType: string;
            value: string;
        }>;
        groupFriendlyName: string;
        groupKey: string;
    };
}) => {
    const firstName = userInfo.fields.filter((field) => field.fieldKey === "firstName")[0]?.value;
    if (!data.firstName) {
        data.firstName = firstName;
    }

    const lastName = userInfo.fields.filter((field) => field.fieldKey === "lastName")[0]?.value;
    if (!data.lastName) {
        data.lastName = lastName;
    }

    const fullName = userInfo.fields.filter((field) => field.fieldKey === "fullName")[0]?.value;
    if (!data.fullName) {
        data.fullName = fullName;
    }

    const nationality = userInfo.fields.filter((field) => field.fieldKey === "nationality")[0]
        ?.value;
    if (!data.nationality) {
        data.nationality = nationality;
    } else if (data.nationality && nationality && data.nationality !== nationality) {
        result.mismatchedData["nationality"] = {
            current: nationality,
            previous: data.nationality,
        };
        data.nationality = nationality;
    }

    const countryCode = userInfo.fields.filter((field) => field.fieldKey === "countryCode")[0]
        ?.value;
    if (!data.countryCode) {
        data.countryCode = countryCode;
    } else if (data.countryCode && countryCode && data.countryCode !== countryCode) {
        result.mismatchedData["countryCode"] = {
            current: countryCode,
            previous: data.countryCode,
        };
        data.countryCode = countryCode;
    }

    const dateOfBirthField = userInfo.fields.filter((field) => field.fieldKey === "dateOfBirth")[0];
    const dateOfBirthValue = dateOfBirthField?.value;
    const dateOfBirthFormat = dateOfBirthField?.uiFieldType;
    const extractedDateOfBirth = extractDate(dateOfBirthValue, dateOfBirthFormat);
    if (!data.dateOfBirth) {
        data.dateOfBirth = extractedDateOfBirth;
    } else if (
        data.dateOfBirth &&
        extractedDateOfBirth &&
        data.dateOfBirth !== extractedDateOfBirth
    ) {
        result.mismatchedData["dateOfBirth"] = {
            current: extractedDateOfBirth,
            previous: data.dateOfBirth,
        };
        data.dateOfBirth = extractedDateOfBirth;
    }

    const placeOfBirth = userInfo.fields.filter((field) => field.fieldKey === "placeOfBirth")[0]
        ?.value;
    if (!data.placeOfBirth) {
        data.placeOfBirth = placeOfBirth;
    } else if (data.placeOfBirth && placeOfBirth && data.placeOfBirth !== placeOfBirth) {
        result.mismatchedData["placeOfBirth"] = {
            current: placeOfBirth,
            previous: data.placeOfBirth,
        };
        data.placeOfBirth = placeOfBirth;
    }
};

const processIdInfoFields = ({
    idInfo,
    data,
    result,
}: {
    data: Data;
    result: FaceTecResponse;
    idInfo: {
        fields: Array<{
            fieldKey: string;
            uiFieldType: string;
            value: string;
        }>;
        groupFriendlyName: string;
        groupKey: string;
    };
}) => {
    const idNumber = idInfo.fields.filter((field) => field.fieldKey === "idNumber")[0]?.value;
    if (!data.idNumber) {
        data.idNumber = idNumber;
    } else if (data.idNumber && idNumber && data.idNumber !== idNumber) {
        result.mismatchedData["idNumber"] = {
            current: idNumber,
            previous: data.idNumber,
        };
        data.idNumber = idNumber;
    }

    const idBarcode = idInfo.fields.filter((field) => field.fieldKey === "barcode")[0]?.value;
    if (!data.idBarcode) {
        data.idBarcode = idBarcode;
    }
    const dateOfIssueField = idInfo.fields.filter((field) => field.fieldKey === "dateOfIssue")[0];
    const dateOfIssueValue = dateOfIssueField?.value;
    const dateOfIssueFormat = dateOfIssueField?.uiFieldType;
    const extractedDateOfIssue = extractDate(dateOfIssueValue, dateOfIssueFormat);
    if (!data.dateOfIssue) {
        data.dateOfIssue = extractedDateOfIssue;
    } else if (
        data.dateOfIssue &&
        extractedDateOfIssue &&
        data.dateOfIssue !== extractedDateOfIssue
    ) {
        result.mismatchedData["dateOfIssue"] = {
            current: extractedDateOfIssue,
            previous: data.dateOfIssue,
        };
        data.dateOfIssue = extractedDateOfIssue;
    }

    const dateOfExpirationField = idInfo.fields.filter(
        (field) => field.fieldKey === "dateOfExpiration",
    )[0];
    const dateOfExpirationValue = dateOfExpirationField?.value;
    const dateOfExpirationFormat = dateOfExpirationField?.uiFieldType;
    const extractedDateOfExpiration = extractDate(dateOfExpirationValue, dateOfExpirationFormat);
    if (!data.dateOfExpiration) {
        data.dateOfExpiration = extractedDateOfExpiration;
    } else if (
        data.dateOfExpiration &&
        extractedDateOfExpiration &&
        data.dateOfExpiration !== extractedDateOfExpiration
    ) {
        result.mismatchedData["dateOfExpiration"] = {
            current: extractedDateOfExpiration,
            previous: data.dateOfExpiration,
        };
        data.dateOfExpiration = extractedDateOfExpiration;
    }

    const countryCode = idInfo.fields.filter((field) => field.fieldKey === "countryCode")[0]?.value;
    if (!data.countryCode) {
        data.countryCode = countryCode;
    } else if (data.countryCode && countryCode && data.countryCode !== countryCode) {
        result.mismatchedData["countryCode"] = {
            current: countryCode,
            previous: data.countryCode,
        };
        data.countryCode = countryCode;
    }
};

const processDocumentData = ({ data, result }: { data: Data; result: FaceTecResponse }) => {
    if (!result.documentData) {
        return;
    }

    var userInfo = result.documentData.scannedValues.groups[0];
    if (userInfo && userInfo.fields) {
        processUserInfoFields({ result, data, userInfo });

        if (result.axonMismatchInformation) {
            return;
        }
    }

    var idInfo = result.documentData.scannedValues.groups[1];
    if (idInfo && idInfo.fields) {
        processIdInfoFields({ idInfo, data, result });
        if (result.axonMismatchInformation) {
            return;
        }
    }

    var secondaryUserInfo = result.documentData.scannedValues.groups[2];
    if (secondaryUserInfo && idInfo.fields) {
        if (!data.sex) {
            data.sex = secondaryUserInfo.fields.filter(
                (field) => field.fieldKey === "sex",
            )[0]?.value;
        }
    }

    data.barcodeTemplateName = result.documentData.templateInfo?.templateName;
    data.barcodeTemplateType = result.documentData.templateInfo?.templateType;
};

const processOcrData = ({ data, result }: { data: Data; result: FaceTecResponse }) => {
    if (!result.ocrResults) {
        return;
    }

    var userInfo = result.ocrResults.ocrResults.scanned.groups[0];
    if (userInfo && userInfo.fields) {
        processUserInfoFields({ userInfo, data, result });
        if (result.axonMismatchInformation) {
            return;
        }
    }

    var idInfo = result.ocrResults.ocrResults.scanned.groups[1];
    if (idInfo && idInfo.fields) {
        processIdInfoFields({ idInfo, data, result });
        if (result.axonMismatchInformation) {
            return;
        }
    }

    var addressInfo = result.ocrResults.ocrResults.scanned.groups[2];
    if (addressInfo && addressInfo.fields) {
        data.address = addressInfo.fields.filter((field) => field.fieldKey === "address")[0]?.value;
    }

    var secondaryUserInfo = result.ocrResults.ocrResults.scanned.groups[3];
    if (secondaryUserInfo && secondaryUserInfo.fields) {
        if (!data.sex) {
            data.sex = secondaryUserInfo.fields.filter(
                (field) => field.fieldKey === "sex",
            )[0]?.value;
        }
    }

    data.ocrTemplateName = result.ocrResults.ocrResults?.templateName;
    data.ocrTemplateType = result.ocrResults.ocrResults?.templateType;
};

export const processGCCID = ({
    decoded,
    result,
    data,
}: {
    decoded: DecodedType;
    result: FaceTecResponse;
    data: Data;
}) => {
    const extractCountryCode = decoded.issuingCountry;
    if (!data.countryCode) {
        data.countryCode = extractCountryCode;
    } else if (calculateMatchScore(data.countryCode, extractCountryCode) < STRING_MATCH_MIN_SCORE) {
        result.axonMismatchInformation = true;
        result.mismatchedData["countryCode"] = {
            current: extractCountryCode,
            previous: data.countryCode,
        };
        return;
    }

    const extractNationality = decoded.nationality;
    if (!data.nationality) {
        data.nationality = extractNationality;
    } else if (calculateMatchScore(data.nationality, extractNationality) < STRING_MATCH_MIN_SCORE) {
        result.axonMismatchInformation = true;
        result.mismatchedData["nationality"] = {
            current: extractNationality,
            previous: data.nationality,
        };
        return;
    }

    const extractDateOfExpiration = decoded.expiryDate;
    if (!data.dateOfExpiration) {
        data.dateOfExpiration = extractDateOfExpiration;
    } else if (
        calculateMatchScore(data.dateOfExpiration, extractDateOfExpiration) < STRING_MATCH_MIN_SCORE
    ) {
        result.axonMismatchInformation = true;
        result.mismatchedData["dateOfExpiration"] = {
            current: extractDateOfExpiration,
            previous: data.dateOfExpiration,
        };
        return;
    }

    const extractIdNumber = decoded.documentNumber;
    if (!data.idNumber) {
        data.idNumber = extractIdNumber;
    } else if (calculateMatchScore(data.idNumber, extractIdNumber) < STRING_MATCH_MIN_SCORE) {
        result.axonMismatchInformation = true;
        result.mismatchedData["idNumber"] = {
            current: extractIdNumber,
            previous: data.idNumber,
        };

        return;
    }

    const extractDateOfBirth = decoded.birthDate;
    data.dateOfBirth = extractDateOfBirth;

    const extractPlaceOfBirth = decoded.nationality;
    data.placeOfBirth = extractPlaceOfBirth;

    const extractFirstName = decoded.givenNames;
    let ocrFirstName = data.firstName;
    let ocrLastName = data.lastName;

    if (!ocrFirstName) {
        data.firstName = extractFirstName;
    } else if (ocrFirstName && extractFirstName && ocrFirstName !== extractFirstName) {
        data.firstName = ocrFirstName;
    }

    const extractLastName = decoded.surname;
    if (!ocrLastName) {
        data.lastName = extractLastName;
    } else if (ocrLastName && extractLastName && ocrLastName !== extractLastName) {
        data.lastName = ocrLastName;

        if (ocrFirstName === ocrLastName) {
            // remove the firstname and last name we decoded from lastName
            ocrLastName.replace(extractFirstName, "").trim();
            ocrLastName.replace(extractLastName, "").trim();
            // prepend whatever is left to firstName
            data.firstName = `${extractFirstName} ${ocrLastName}`;
            // set last name to what we extracted
            data.lastName = extractLastName;
        }
    }

    const extractFullName = `${data.lastName} ${data.firstName}`;
    if (!data.fullName) {
        data.fullName = extractFullName;
    }

    const extractSex = decoded.sex;
    data.sex = extractSex;

    result.axonMismatchInformation = false;
    result.axonMissingInformation = false;
};

export const processMrzData = ({
    result,
    data,
    mrzService,
}: {
    result: any;
    data: Data;
    mrzService: MRZDecoderService;
}) => {
    try {
        const idInfo = result.documentData.scannedValues.groups[1];
        const mrz = [];

        const mrzLine1Field = idInfo.fields.find((field) => field.fieldKey === "mrzLine1");

        if (!mrzLine1Field) {
            return;
        }
        mrz.push(mrzLine1Field.value);

        const mrzLine2Field = idInfo.fields.find((field) => field.fieldKey === "mrzLine2");

        if (!mrzLine2Field) {
            return;
        }
        mrz.push(mrzLine2Field.value);

        const mrzLine3Field = idInfo.fields.find((field) => field.fieldKey === "mrzLine3");

        if (mrzLine3Field) {
            mrz.push(mrzLine3Field.value);
        }

        const decoded = mrzService.decode(mrz);

        if (decoded.type === MRZType.SAUDI_ID ||
            decoded.type === MRZType.KUWAIT_ID ||
            decoded.type === MRZType.BAHRAIN_ID) {
            processGCCID({ decoded, result, data });
        }
    } catch (e) {
        // do nothing
    }
};

export const processPhotos = ({ result, data }: { result: FaceTecResponse; data: Data }) => {
    var idPhotoFront = data.imageList.filter((item) => item.type === ImageTypeEnum.IdPhotoFront)[0];
    if (result.photoIDFrontCrop) {
        if (idPhotoFront) {
            idPhotoFront.image = result.photoIDFrontCrop;
        } else {
            data.imageList.push({
                image: result.photoIDFrontCrop,
                type: ImageTypeEnum.IdPhotoFront,
            });
        }
    }

    var idPhotoBack = data.imageList.filter((item) => item.type === ImageTypeEnum.IdPhotoBack)[0];
    if (result.photoIDBackCrop) {
        if (idPhotoBack) {
            idPhotoBack.image = result.photoIDBackCrop;
        } else {
            data.imageList.push({
                image: result.photoIDBackCrop,
                type: ImageTypeEnum.IdPhotoBack,
            });
        }
    }

    var idPhotoFace = data.imageList.filter((item) => item.type === ImageTypeEnum.IdPhotoFace)[0];
    if (result.photoIDFaceCrop) {
        if (idPhotoFace) {
            idPhotoFace.image = result.photoIDFaceCrop;
        } else {
            data.imageList.push({
                image: result.photoIDFaceCrop,
                type: ImageTypeEnum.IdPhotoFace,
            });
        }
    }

    var idSignature = data.imageList.filter((item) => item.type === ImageTypeEnum.IdSignature)[0];
    if (result.photoIDPrimarySignatureCrop) {
        if (idSignature) {
            idSignature.image = result.photoIDPrimarySignatureCrop;
        } else {
            data.imageList.push({
                image: result.photoIDPrimarySignatureCrop,
                type: ImageTypeEnum.IdSignature,
            });
        }
    }

    var tamperFront = data.imageList.filter(
        (item) => item.type === ImageTypeEnum.TamperingFrontEvidence,
    )[0];
    if (result.photoIDTamperingEvidenceFrontImage) {
        if (tamperFront) {
            tamperFront.image = result.photoIDTamperingEvidenceFrontImage;
        } else {
            data.imageList.push({
                image: result.photoIDTamperingEvidenceFrontImage,
                type: ImageTypeEnum.TamperingFrontEvidence,
            });
        }
    }

    var tamperBack = data.imageList.filter(
        (item) => item.type === ImageTypeEnum.TamperingBackEvidence,
    )[0];
    if (result.photoIDTamperingEvidenceBackImage) {
        if (tamperBack) {
            tamperBack.image = result.photoIDTamperingEvidenceBackImage;
        } else {
            data.imageList.push({
                image: result.photoIDTamperingEvidenceBackImage,
                type: ImageTypeEnum.TamperingBackEvidence,
            });
        }
    }

    data.idPhotoMatchLevel = result.matchLevel;
    data.ageEstimate = result.idScanAgeEstimateGroupV2EnumInt; //could also be result.idScanAgeV2GroupEnumInt (who the fuck knows)
};

export const processIdResult = ({
    result,
    dataService,
    configService,
    mrzService,
    handleErrors,
    proceed,
}: {
    result: FaceTecResponse;
    dataService: DataService;
    configService: ConfigService;
    mrzService: MRZDecoderService;
    handleErrors: (res: FaceTecResponse) => boolean;
    proceed: () => void;
}) => {
    const data = dataService.getData();
    data.axonExtractedData = result.axonExtractedData;

    const config = configService.getConfig();
    result.axonMismatchInformation = false;
    result.mismatchedData = {};

    if (result.didMatchIDScanToOCRTemplate === true) {
        if (result.documentData) {
            processDocumentData({ data, result });
        }

        if (result.ocrResults) {
            processOcrData({ data, result });
        }

        // final source of truth
        if (result.documentData) {
            processMrzData({ result, data, mrzService });
        }
    }

    // process the photos if any
    processPhotos({ data, result });

    if (result.additionalSessionData) {
        data.browser = result.additionalSessionData.userAgent;
        data.deviceModel = result.additionalSessionData.deviceModel;
        data.deviceSDK = result.additionalSessionData.deviceSDKVersion;
        data.platform = result.additionalSessionData.platform;
        data.ipAddress = result.additionalSessionData.ipAddress;
    }

    setIdGroupName(config, data);

    dataService.setData(data);

    if (configService.getRetryCounter() > MAX_RETRIES) {
        handleErrors({ didCompleteIDScanWithoutMatching: true } as FaceTecResponse);
        return;
    }

    if (!result.isCompletelyDone) {
        return;
    }

    if (result.axonMissingInformation) {
        handleErrors({ ...result });
        return;
    }

    if (result.matchLevel < configService.getConfig().minMatchLevel) {
        console.log("Handling Error Match Failed:", result);
        handleErrors({ ...result, didCompleteIDScanWithoutMatching: true });
        return;
    }

    if (result.axonMismatchInformation) {
        handleErrors({ ...result, axonMismatchInformation: true });
        return;
    }

    if (
        result.isCompletelyDone === true &&
        result.matchLevel >= configService.getConfig().minMatchLevel &&
        !result.axonMissingInformation
    ) {
        proceed();
        return;
    } else {
        console.log("Handling Error Match Failed:", result);
        handleErrors({ ...result, didCompleteIDScanWithoutMatching: true });
        return;
    }
};

export const processPortraitResult = (result: any, dataService: DataService) => {
    const data = dataService.getData();
    console.log(data);

    var portrait = data.imageList.filter((item) => item.type === ImageTypeEnum.Portrait)[0];
    if (portrait) {
        portrait.image = result;
        console.log("Data:", data);
        return;
    }
    data.imageList.push({
        image: result,
        type: ImageTypeEnum.Portrait,
    });

    dataService.setData(data);
    console.log("Data:", data);
};

export const trackJourney = ({
    ref,
    page,
    httpService,
}: {
    ref: string;
    page: string;
    httpService: HttpFacetecService;
}) => {
    httpService.trackJourney(ref, page).subscribe((_data) => {
        console.log(`${page} tracked`);
    });
};
