<div class="light-grey" #top>
	<div class="row">
		<div class="col text-center title">{{'authentication' | translate}}</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-0 bg-white shadow-sm border-white rounded">
				<div class="card-body p-0 pb-2">
					<div class="block-background">
						<div class="block-info">
							<div id="status">
							</div>
							<div *ngIf="ready">
								<div class="row">
									<div class="col">
										<div class="card m-3 bg-white border-danger rounded">
											<div class="card-body">
												<div class="row">
													<div class="col">
														<div class="row">
														</div>
														<div class="row mt-2">
															<div class="col text-start"><small>{{'activate_esim_notification' | translate}}</small></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col">
										<div class="card m-3 bg-white rounded">
											<div class="card-body">
												<div class="row">
													<div class="col-2"><img src="assets/images/portrait.png"
															height="30px"></div>
													<div class="col">
														<div class="row">
															<div class="col text-start title">{{'step' | translate}}
																{{'one' | translate}}: {{'take_your_photo' | translate}}
															</div>
														</div>
														<div class="row mt-2">
															<div class="col text-start"><small>{{'typ_description' |
																	translate}}</small></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<div class="card m-3 bg-white rounded">
											<div class="card-body">
												<div class="row">
													<div class="col-2"><img src="assets/images/document.png"
															height="30px"></div>
													<div class="col">
														<div class="row">
															<div class="col text-start title">{{'step' | translate}}
																{{'two' | translate}}: {{'scan_your_document' |
																translate}}
															</div>
														</div>
														<div class="row mt-2">
															<div class="col text-start"><small>{{'syd_description' |
																	translate}}</small></div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col" class="allowed-id-list text-start">
														<small>{{'bahrain_cpr' | translate}}</small><br />
														<small>{{'all_passports' | translate}}</small><br />
														<small>{{'gcc_id' | translate}}</small><br />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<div class="card m-3 bg-white rounded">
											<div class="card-body">
												<div class="row">
													<div class="col-2"><img src="assets/images/review.png"
															height="30px"></div>
													<div class="col">
														<div class="row">
															<div class="col text-start title">{{'step' | translate}}
																{{'three' | translate}}: {{'review_information'
																|translate}}
															</div>
														</div>
														<div class="row mt-2">
															<div class="col text-start"><small>{{'ri_description' |
																	translate}}</small></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<div class="card m-3 bg-white rounded">
											<div class="card-body">
												<div class="row">
													<div class="col-2"><img src="assets/images/signature_red.png"
															height="30px"></div>
													<div class="col">
														<div class="row">
															<div class="col text-start title">{{'step' | translate}}
																{{'four' | translate}}:
																{{'add_your_signature' | translate}}
															</div>
														</div>
														<div class="row mt-2">
															<div class="col text-start"><small>{{'ays_description' |
																	translate}}</small></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row mt-2">
									<div class="d-grid gap-2 col-6 mx-auto space-after grid-button">
										<button (click)="start()"
											class="btn bg-gradient btn-zain btn-zain-next">{{'start' | translate}}</button>
									</div>
								</div>

							</div>
							<div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>