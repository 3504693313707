import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class RefService {
  private storageKey = 'refData';

  setRef(ref: string) {
    sessionStorage.setItem(this.storageKey, ref);
  }

  getRef(): string | null {
    return sessionStorage.getItem(this.storageKey);
  }
}