import { HttpParams, HttpEvent } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AbstractHttpService } from "./http-abstract-service";
import { Data, Error, ErrorType, Ref, Response } from "../modules/facetec/dto";

@Injectable({
    providedIn: "root",
})
export class HttpFacetecService extends AbstractHttpService {
    constructor(http: HttpClient) {
        super(http, "");
    }

    sendExternalDBRef(ref: string, externalDatabaseRef: string, inferenceId: string) {
        return super.post(
            `/${ref}/facetec?facetecId=${encodeURIComponent(
                externalDatabaseRef,
            )}&inferenceId=${encodeURIComponent(inferenceId)}`,
            {},
        );
    }

    validateRef(ref: Ref): Observable<Response> {
        return super.post(`/${ref.ref}/validate`, ref);
    }

    audit(ref: string, input: Error): Observable<Response> {
        var payload = this.buildErrorPayload(input);
        switch (input.errorType) {
            case ErrorType.FaceLivenessFailed:
                console.log("Audit error type: FaceLivenessFailed");
                break;
            case ErrorType.OcrTemplateMatchFailed:
                console.log("Audit error type: OcrTemplateMatchFailed");
                break;
            case ErrorType.FaceDidNotMatchId:
                console.log("Audit error type: FaceDidNotMatchId");
                break;
            case ErrorType.IdTypeNotAllowed:
                console.log("Audit error type: IdTypeNotAllowed");
                break;
            case ErrorType.IdTypeUnexpectedMedia:
                console.log("Audit error type: IdTypeUnexpectedMedia");
                break;
            case ErrorType.IdScanFailed:
                console.log("Audit error type: IdScanFailed");
                break;
            default:
                console.log("Audit error type: Unknown");
                break;
        }
        return super.post(`/${ref}/audit`, payload);
    }

    processError(ref: string, input: Error): Observable<Response> {
        var payload = this.buildErrorPayload(input);
        return super.post(`/${ref}/process_error`, payload);
    }

    process(ref: string, payload: Data): Observable<Response> {
        return super.post(`/${ref}/process`, payload);
    }

    trackJourney(ref: string, page: string): Observable<Response> {
        return super.post(`/${ref}/journey_tracking?page=${page}`, null);
    }

    private buildErrorPayload(input: Error): Error {
        var data: Data = {
            sessionId: input.data.sessionId,
            firstName: input.data.firstName,
            lastName: input.data.lastName,
            fullName: input.data.fullName,
            nationality: input.data.nationality,
            nationalityNonMRZValue: input.data.nationalityNonMRZValue,
            countryCode: input.data.countryCode,
            countryCodeNonMRZValue: input.data.countryCodeNonMRZValue,
            dateOfBirth: input.data.dateOfBirth,
            placeOfBirth: input.data.placeOfBirth,
            idNumber: input.data.idNumber,
            idBarcode: input.data.idBarcode,
            idGroupName: input.data.idGroupName,
            dateOfIssue: input.data.dateOfIssue,
            dateOfExpiration: input.data.dateOfExpiration,
            sex: input.data.sex,
            address: input.data.address,
            imageList: input.data.imageList,
            userConfirmedValues: input.data.userConfirmedValues,
            barcodeTemplateName: input.data.barcodeTemplateName,
            barcodeTemplateType: input.data.barcodeTemplateType,
            ocrTemplateName: input.data.ocrTemplateName,
            ocrTemplateType: input.data.ocrTemplateType,
            idPhotoMatchLevel: input.data.idPhotoMatchLevel,
            ageEstimate: input.data.ageEstimate,
            browser: input.data.browser,
            deviceModel: input.data.deviceModel,
            deviceSDK: input.data.deviceSDK,
            ipAddress: input.data.ipAddress,
            platform: input.data.platform,
            axonExtractedData: input.data.axonExtractedData,
            idNumber2: input.data.idNumber2,
        };
        var payload: Error = {
            errorType: input.errorType,
            data: data,
        };
        return payload;
    }
}
