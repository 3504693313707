import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Config, Data, Error, ErrorType } from "../../../dto";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilService } from "src/app/service/util.service";
import packageInfo from "../../../../../../../package.json";
import { Config as FacetecConfig } from "../../../../../../assets/Config";
import { FaceTecSDKService } from "src/app/service/FaceTecSDKService";
import { RefService } from "src/app/service/ref.service";
import { ConfigService } from "src/app/service/config.service";
import { DataService } from "src/app/service/data.service";
import { trackJourney } from "src/app/utils/utils";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-facetec",
    templateUrl: "./facetec.component.html",
    styleUrls: ["./facetec.component.scss"],
})
export class FaceTecComponent implements OnInit, OnDestroy {
    private routeSubscription: Subscription;
    public version = packageInfo.version;

    ready: boolean = false;

    data: Data;

    ref: string;
    config: Config;
    facetecUrl: string;

    util = UtilService;

    direction;

    @ViewChild("top") top: ElementRef;

    constructor(
        private refService: RefService,
        private configService: ConfigService,
        private route: ActivatedRoute,
        private router: Router,
        private facetecSDKService: FaceTecSDKService,
        private dataService: DataService,
        private httpService: HttpFacetecService,
        private translateService: TranslateService,
        private toastr: ToastrService,
    ) {
        this.direction = this.translateService.currentLang === 'ar' ? 'rtl' : 'ltr';
    }

    private reset() {
        this.data = new Data();
        this.data.imageList = [];
        this.data.ref = this.ref;

        this.dataService.setData(this.data);
        this.refService.setRef(this.ref);
        this.configService.setConfig(this.config);
    }

    async ngOnInit() {
        console.log("Facetec::ngOnInit");

        this.translateService.onLangChange.subscribe(event => {
            console.log("Facetec::onLangChange", event);
            this.direction = event.lang === 'ar' ? 'rtl' : 'ltr';
        });


        this.data = this.dataService.getData();
        this.ref = this.refService.getRef();

        if (!this.ref) {
            this.toastr.error(await this.translateService.get("invalid_reference_provided").toPromise(), await this.translateService.get("error").toPromise());
            return;
        }

        const lang = this.configService.getLanguage() || 'en';
        this.translateService.use(lang);
        
        this.config = this.configService.getConfig() as Config;
        FacetecConfig.ref = this.ref;
        var tryAgain = this.configService.getTryAgain();

        this.ready = true;

        if (tryAgain) {
            this.ready = true;
            this.startFaceTec();
        }
    }

    startFaceTec() {
        console.log("Facetec::start");
        this.reset();
        if (this.top) {
            this.top.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        trackJourney({
            ref: this.ref,
            page: "sdk_face_capture_start",
            httpService: this.httpService,
        });
        this.facetecSDKService.onPhotoIDMatchPressed();
    }

    start() {
        this.router.navigate(["/takephoto"]);
    }

    submitData() {
        this.router.navigate(["/submit"]);
    }
    sign() {
        this.router.navigate(["/sign"]);
    }

    ngOnDestroy(): void {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }
}
