import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Data, Image, ImageTypeEnum } from "../../../dto";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import SignaturePad from "signature_pad";
import { DataService } from "src/app/service/data.service";
import { trackJourney } from "src/app/utils/utils";
import { RefService } from "src/app/service/ref.service";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import { ConfigService } from "src/app/service/config.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-signature",
    templateUrl: "./signature.component.html",
    styleUrls: ["./signature.component.scss"],
})
export class SignatureComponent implements AfterViewInit {
    data: Data;

    signatureNeeded!: boolean;
    signaturePad!: SignaturePad;
    @ViewChild("canvas") canvasEl!: ElementRef;
    signatureImg!: string;

    constructor(
        private router: Router,
        private refService: RefService,
        private httpService: HttpFacetecService,
        private configService: ConfigService,
        private translateService: TranslateService,
        private sanitizer: DomSanitizer,
        private dataService: DataService,
    ) {
        trackJourney({
            ref: this.refService.getRef(),
            page: "signature_start",
            httpService: this.httpService,
        });
        this.data = dataService.getData();
        const lang = this.configService.getLanguage() || 'en';
        this.translateService.use(lang);

    }

    ngAfterViewInit(): void {
        this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    }

    startDrawing(event: Event) {
        // works in device not in browser
    }
    moved(event: Event) {
        // works in device not in browser
    }
    savePad() {}
    clearPad() {
        this.signaturePad.clear();
    }

    submit() {
        this.signatureNeeded = this.signaturePad.isEmpty();
        if (this.signatureNeeded) {
            return;
        }

        const base64Data = this.signaturePad.toDataURL();
        var signature = this.data.imageList.filter(
            (item) => item.type === ImageTypeEnum.Signature,
        )[0];
        if (signature) {
            signature.image = base64Data;
        } else {
            this.data.imageList.push({
                image: base64Data,
                type: ImageTypeEnum.Signature,
            });
        }

        this.dataService.setData(this.data);

        trackJourney({
            ref: this.refService.getRef(),
            page: "signature_success",
            httpService: this.httpService,
        });		
        this.router.navigate(["/submit"]);
    }
}
