import { Injectable } from "@angular/core";
import { Data } from "../modules/facetec/dto";
import { safeStringify } from "ajv/dist/compile/codegen/code";

@Injectable({
  providedIn: "root",
})
export class DataService {
  private readonly STORAGE_KEY = 'facetec_data';

  private readonly FRONT_DATA_KEY = 'facetec_frontData';

  setFrontData(data: Data): void {
    sessionStorage.setItem(this.FRONT_DATA_KEY, safeStringify(data));
  }

  getFrontData(): Data | null {
    const storedData = sessionStorage.getItem(this.FRONT_DATA_KEY);
    return storedData ? JSON.parse(storedData) : null;
  }
  
  setData(data: Data): void {
    sessionStorage.setItem(this.STORAGE_KEY, safeStringify(data));
  }

  getData(): Data | null {
    const storedData = sessionStorage.getItem(this.STORAGE_KEY);
    return storedData ? JSON.parse(storedData) : null;
  }

  // Optional: Method to clear stored data
  clearData(): void {
    sessionStorage.removeItem(this.STORAGE_KEY);
  }
}