<div class="light-grey" [class.text-end]="direction === 'rtl'" [class.text-start]="direction === 'ltr'">
	<div class="row">
		<div class="col text-center title">{{'confirm_your_details' | translate}}</div>
	</div>
	<div class="review-info-subtitle text-black">
		<div class="row mt-3">
			<div class="col title">{{'confirmation' | translate}}</div>
		</div>
		<div class="row mt-3">
			<div class="col fs-6">{{'cyd_description' | translate}}</div>
		</div>
	</div>

	<div class="row mt-3">
		<div class="col">
			<div class="card m-0 bg-white shadow-sm border-white rounded">
				<div class="card-body p-0 pb-2">
					<div class="block-background">
						<div>
							<div class="row mt-3" *ngFor="let item of reviewData">
								<div class="col px-1 mb-2">
									<label for="{{item.id}}" class="ms-2 position-absolute"
										style="margin-top: -0.25rem !important">
										<span class="h6 bg-white px-1 fw-bold">{{item.title}}</span>
									</label>
									<input type="text" class="form-control mt-2 text-muted" id="{{item.id}}" value="{{item.value}}" readonly>
								</div>
							</div>
							<div class="row mt-3">
								<div class="d-grid gap-2 col-6 mx-auto space-after grid-button">
									<button (click)="proceed()"
										class="btn bg-gradient btn-zain btn-zain-next">{{'confirm' | translate}}</button>
								</div>
							</div>
							<div class="row mt-1">
								<div class="d-grid gap-2 col-6 mx-auto space-after grid-button">
									<button class="btn btn-zain" (click)="cancel()">{{'try_again' | translate}}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>