import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { CustomLoggerService } from "./app/service/logger.service";
import { SlackService } from "./app/service/slack.service";

if (environment.production) {
  enableProdMode();
}

const slackService = new SlackService();
const logger = new CustomLoggerService(slackService);

// Override console methods
// console.log = (msg: string, ...args: any[]) => logger.log(msg, ...args);
// console.error = (msg: string, ...args: any[]) => logger.error(msg, ...args);
// console.warn = (msg: string, ...args: any[]) => logger.warn(msg, ...args);


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
