import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Data, Image, ImageTypeEnum } from "../../../dto";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import { catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/service/data.service";
import { trackJourney } from "src/app/utils/utils";
import { RefService } from "src/app/service/ref.service";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "src/app/service/config.service";

@Component({
    selector: "app-submit",
    templateUrl: "./submit.component.html",
    styleUrls: ["./submit.component.scss"],
})
export class SubmitComponent {
    data: Data;

    direction;
    constructor(
        private router: Router,
        private httpService: HttpFacetecService,
        private refService: RefService,
        private configService: ConfigService,
        private dataService: DataService,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private translateService: TranslateService,
        private zone: NgZone,
    ) {
        trackJourney({
            ref: this.refService.getRef(),
            page: "submit_start",
            httpService: this.httpService,
        });
        this.data = this.dataService.getData();
        const lang = this.configService.getLanguage() || 'en';
        this.translateService.use(lang);
        
        this.direction = this.translateService.currentLang === 'ar' ? 'rtl' : 'ltr';
        
        console.log("Direction", this.direction);
        console.log("Current Lang", this.translateService.currentLang);

        this.translateService.onLangChange.subscribe(event => {
            this.direction = event.lang === 'ar' ? 'rtl' : 'ltr';
        });
    }

    getImage(ordinal: number) {
        switch (ordinal) {
            case ImageTypeEnum.Portrait:
                return (
                    "data:image/jpeg;base64," +
                    this.data.imageList.filter((item) => item.type == ImageTypeEnum.Portrait)[0]
                        .image
                );
            case ImageTypeEnum.IdPhotoFace:
                return (
                    "data:image/jpeg;base64," +
                    this.data.imageList.filter((item) => item.type == ImageTypeEnum.IdPhotoFace)[0]
                        .image
                );
            case ImageTypeEnum.IdPhotoFront:
                return (
                    "data:image/jpeg;base64," +
                    this.data.imageList.filter((item) => item.type == ImageTypeEnum.IdPhotoFront)[0]
                        .image
                );
            case ImageTypeEnum.IdPhotoBack:
                return (
                    "data:image/jpeg;base64," +
                    this.data.imageList.filter((item) => item.type == ImageTypeEnum.IdPhotoBack)[0]
                        .image
                );
            case ImageTypeEnum.IdSignature:
                return (
                    "data:image/jpeg;base64," +
                    this.data.imageList.filter((item) => item.type == ImageTypeEnum.IdSignature)[0]
                        .image
                );
            case ImageTypeEnum.Signature:
                return this.data.imageList.filter((item) => item.type == ImageTypeEnum.Signature)[0]
                    .image;
            default:
                return null;
        }
    }

    getImageType(ordinal: number) {
        switch (ordinal) {
            case ImageTypeEnum.Portrait:
                return "Portrait";
            case ImageTypeEnum.IdPhotoFace:
                return "ID Face";
            case ImageTypeEnum.IdPhotoFront:
                return "ID Front";
            case ImageTypeEnum.IdPhotoBack:
                return "ID Back";
            case ImageTypeEnum.IdSignature:
                return "ID Signature";
            case ImageTypeEnum.Signature:
                return "Signature";
            default:
                return "Unknown";
        }
    }

    submit() {
        var images = this.data.imageList.map((item) => {
            var imageData = item.image;
            if (item.type === ImageTypeEnum.Signature) {
                //Remove the base64 prefix crap
                imageData = (imageData as string).replace("data:image/png;base64,", "");
            }

            return {
                image: imageData,
                type: item.type,
            };
        });

        var data: Data = {
            sessionId: this.data.sessionId,
            firstName: this.data.firstName,
            lastName: this.data.lastName,
            fullName: this.data.fullName,
            nationality: this.data.nationality,
            nationalityNonMRZValue: this.data.nationalityNonMRZValue,
            countryCode: this.data.countryCode,
            countryCodeNonMRZValue: this.data.countryCodeNonMRZValue,
            dateOfBirth: this.data.axonExtractedData.dateOfBirth,
            placeOfBirth: this.data.placeOfBirth,
            idNumber: this.data.idNumber,
            idGroupName: this.data.idGroupName,
            idBarcode: this.data.idBarcode,
            dateOfIssue: this.data.axonExtractedData.dateOfIssue,
            dateOfExpiration: this.data.axonExtractedData.dateOfExpiration,
            sex: this.data.sex, //yes pleassexe
            address: this.data.address,
            imageList: images,
            userConfirmedValues: this.data.userConfirmedValues,
            barcodeTemplateName: this.data.barcodeTemplateName,
            barcodeTemplateType: this.data.barcodeTemplateType,
            ocrTemplateName: this.data.ocrTemplateName,
            ocrTemplateType: this.data.ocrTemplateType,
            idPhotoMatchLevel: this.data.idPhotoMatchLevel,
            ageEstimate: this.data.ageEstimate,
            browser: this.data.browser,
            deviceModel: this.data.deviceModel,
            deviceSDK: this.data.deviceSDK,
            ipAddress: this.data.ipAddress,
            platform: this.data.platform,
            axonExtractedData: this.data.axonExtractedData,
            idNumber2: this.data.axonExtractedData.idNumber2,
            idMatchScore: this.data.idMatchScore,
            idPartialMatch: this.data.idPartialMatch,
        };

        this.spinner.show();
        this.httpService
            .process(this.data.ref, data)
            .pipe(
                catchError(async (error) => {
                    this.spinner.hide();
                    console.log("Error submitting data", error);
                    this.toastr.error(error.statusText ? error.statusText : error.message, await this.translateService.get("error").toPromise());
                    throw error;
                }),
            )
            .subscribe(async (result) => {
                this.spinner.hide();
                if (!result) {
                    this.toastr.error(await this.translateService.get("failed_submit_data").toPromise(), await this.translateService.get("error").toPromise());
                    return;
                }
                if (result.status) {
                    trackJourney({
                        ref: this.refService.getRef(),
                        page: "submit_success",
                        httpService: this.httpService,
                    });
                    this.toastr.success(await this.translateService.get("sumitted_success").toPromise(), await this.translateService.get("success").toPromise());

                    if (result.redirectUrl) {
                        this.zone.runOutsideAngular(() => {
                            setTimeout(function () {
                                document.location.href = result.redirectUrl;
                            }, 1000);
                        });
                    }

                    return;
                }

                this.toastr.error(await this.translateService.get("failed_submit_data").toPromise() + " " + result.error, await this.translateService.get("error").toPromise());
            });
    }
}
