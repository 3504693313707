import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { getLocalizedStrings } from "src/app/constants";

@Component({
    selector: "app-language-switcher",
    template: `
        <button (click)="toggleLanguage()" class="language-switcher btn" type="button">
            <img src="assets/images/globe.png"  height="20px">
        </button>
    `,
    styles: [
        `
            .language-switcher {
                position: fixed;
                bottom: 20px;
                right: 10px;
                min-width: 50px !important;
                padding: 10px 20px;
                cursor: pointer;
                z-index: 1000;
            }
        `,
    ],
})
export class LanguageSwitcherComponent implements OnInit {
    currentLanguage: string;
    supportedLanguages = ["ar", "en"]; // Add more languages as needed
    globeIcon = faGlobe;
    constructor(private translateService: TranslateService) { }

    ngOnInit() {
        this.currentLanguage = this.translateService.getBrowserLang();
    }

    toggleLanguage() {
        const currentIndex = this.supportedLanguages.indexOf(this.currentLanguage);
        const nextIndex = (currentIndex + 1) % this.supportedLanguages.length;
        this.currentLanguage = this.supportedLanguages[nextIndex];
        this.translateService.use(this.currentLanguage);

        getLocalizedStrings(this.currentLanguage).then((module) => {
            console.log("onFaceTecSDKInitializationSuccess :: 5", {
                ...module
            });
            FaceTecSDK.configureLocalization({
                ...module
            });
        });        
    }
}
