import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

export enum MRZType {
    TD1 = "TD1",
    TD3 = "TD3",
    SAUDI_ID = "SAUDI_ID",
    KUWAIT_ID = "KUWAIT_ID",
    BAHRAIN_ID = "BAHRAIN_ID"
}

export enum Sex {
    M = "M",
    F = "F",
}

export interface DecodedType {
    type: MRZType;
    documentCode: string;
    issuingCountry: string;
    optionalData1: string;
    documentNumber: string;
    checkDigitDocumentNumber: string;
    birthDate: string;
    checkDigitBirthDate: string;
    sex: Sex;
    expiryDate: string;
    checkDigitExpiryDate: string;
    nationality: string;
    optionalData2: string;
    overallCheckDigit: string;
    surname: string;
    givenNames: string;
}

@Injectable({
    providedIn: "root",
})
export class MRZDecoderService {
    private readonly DOCUMENT_CODE_REGEX = /^[A-Z]{1,2}$/;
    private readonly COUNTRY_CODE_REGEX = /^[A-Z]{3}$/;

    constructor(
        private translateService: TranslateService,
    ) { }

    decode(mrz: string[]): DecodedType {
        if (mrz.length !== 2 && mrz.length !== 3) {
            throw new Error(this.translateService.instant("invalid_mrz"));
        }

        const mrzType = this.detectMRZType(mrz);
        const result: DecodedType = {
            type: mrzType,
        } as DecodedType;

        switch (mrzType) {
            case MRZType.TD1:
                this.decodeTD1(mrz, result);
                break;
            case MRZType.TD3:
                this.decodeTD3(mrz, result);
                break;
            case MRZType.SAUDI_ID:
                this.decodeSaudiID(mrz, result);
                break;
            case MRZType.KUWAIT_ID:
                this.decodeKuwaitID(mrz, result);
                break;
            case MRZType.BAHRAIN_ID:
                this.decodeBahrainId(mrz, result);
        }

        return result;
    }

    private detectMRZType(mrz: string[]): MRZType {
        if (mrz.length === 2) {
            return MRZType.TD3;
        } else if (mrz[0].startsWith("IDSAU")) {
            return MRZType.SAUDI_ID;
        } else if (mrz[0].startsWith("IDKWT")) {
            return MRZType.KUWAIT_ID;
        } else if (mrz[0].startsWith("IDBHR")) {
            return MRZType.BAHRAIN_ID;
        } else {
            return MRZType.TD1;
        }
    }

    private decodeTD1(mrz: string[], result: DecodedType): void {
        const [line1, line2, line3] = mrz;

        result.documentCode = this.extractDocumentCode(line1.slice(0, 2));
        result.issuingCountry = this.extractCountryCode(line1.slice(2, 5));
        result.documentNumber = line1.slice(5, 14).replace(/</g, "");
        result.optionalData1 = line1.slice(15, 30).trim().replace(/</g, "");

        result.birthDate = this.extractDate(line2.slice(0, 6));
        result.sex = Sex[this.extractSex(line2.charAt(7))];
        result.expiryDate = this.extractDate(line2.slice(8, 14), true);
        result.nationality = this.extractCountryCode(line2.slice(15, 18));
        result.optionalData2 = line2.slice(18, 29).trim().replace(/</g, "");

        const names = line3.split("<<");
        result.surname = names[0].replace(/</g, " ").trim();
        result.givenNames = names[1].replace(/</g, " ").trim();
    }

    private decodeTD3(mrz: string[], result: DecodedType): void {
        const [line1, line2] = mrz;

        result.documentCode = this.extractDocumentCode(line1.slice(0, 1));
        result.issuingCountry = this.extractCountryCode(line1.slice(2, 5));

        const names = line1.slice(5).split("<<");
        result.surname = names[0].replace(/</g, " ").trim();
        result.givenNames = names[1].replace(/</g, " ").trim();

        result.documentNumber = line2.slice(0, 9).replace(/</g, "");
        result.nationality = this.extractCountryCode(line2.slice(10, 13));
        result.birthDate = this.extractDate(line2.slice(13, 19));
        result.sex = Sex[this.extractSex(line2.charAt(20))];
        result.expiryDate = this.extractDate(line2.slice(21, 27), true);
        result.optionalData1 = line2.slice(28, 42).trim().replace(/</g, "");
    }

    private decodeBahrainId(mrz: string[], result: DecodedType): void {
        const [line1, line2, line3] = mrz;

        result.documentCode = this.extractDocumentCode(line1.slice(0, 2));
        result.issuingCountry = this.extractCountryCode(line1.slice(2, 5));
        result.documentNumber = line1.slice(5, 14).replace(/</g, "").trim();
        result.checkDigitDocumentNumber = line1.charAt(14);
        result.optionalData1 = line1.slice(17, 30).trim().replace(/</g, "");

        result.birthDate = this.extractDate(line2.slice(0, 6));
        result.checkDigitBirthDate = line2.charAt(6);
        result.sex = Sex[this.extractSex(line2.charAt(7))];
        result.expiryDate = this.extractDate(line2.slice(8, 14), true);
        result.checkDigitExpiryDate = line2.charAt(14);
        result.nationality = this.extractCountryCode(line2.slice(15, 18));
        result.optionalData2 = line2.slice(18, 29).trim().replace(/</g, "");

        const names = line3.split("<<");
        result.surname = names[0].replace(/</g, " ").trim();
        result.givenNames = names.slice(1).join(" ").replace(/</g, " ").trim();
    }

    private decodeKuwaitID(mrz: string[], result: DecodedType): void {
        const [line1, line2, line3] = mrz;

        result.documentCode = this.extractDocumentCode(line1.slice(0, 2));
        result.issuingCountry = this.extractCountryCode(line1.slice(2, 5));
        result.optionalData1 = line1.slice(5, 15).replace(/</g, "");
        result.documentNumber = line1.slice(15, 27).replace(/</g, "");
        result.checkDigitDocumentNumber = "";

        result.birthDate = this.extractDate(line2.slice(0, 6));
        result.checkDigitBirthDate = line2.charAt(6);
        result.sex = Sex[this.extractSex(line2.charAt(7))];
        result.expiryDate = this.extractDate(line2.slice(8, 14), true);
        result.checkDigitExpiryDate = line2.charAt(14);
        result.nationality = this.extractCountryCode(line2.slice(15, 18));
        result.optionalData2 = line2.slice(18, 29).trim().replace(/</g, "");

        const names = line3.split("<<");
        result.surname = names[0].replace(/</g, " ").trim();
        result.givenNames = names.slice(1).join(" ").replace(/</g, " ").trim();
    }

    private decodeSaudiID(mrz: string[], result: DecodedType): void {
        const [line1, line2, line3] = mrz;

        result.documentCode = this.extractDocumentCode(line1.slice(0, 2));
        result.issuingCountry = this.extractCountryCode(line1.slice(2, 5));
        result.documentNumber = line1.slice(5, 16).replace(/</g, "");
        result.checkDigitDocumentNumber = line1.charAt(16);
        result.optionalData1 = line1.slice(17, 30).trim().replace(/</g, "");

        result.birthDate = this.extractDate(line2.slice(0, 6));
        result.checkDigitBirthDate = line2.charAt(6);
        result.sex = Sex[this.extractSex(line2.charAt(7))];
        result.expiryDate = this.extractDate(line2.slice(8, 14), true);
        result.checkDigitExpiryDate = line2.charAt(14);
        result.nationality = this.extractCountryCode(line2.slice(15, 18));
        result.optionalData2 = line2.slice(18, 29).trim().replace(/</g, "");

        const names = line3.split("<<");
        result.surname = names[0].replace(/</g, " ").trim();
        result.givenNames = names.slice(1).join(" ").replace(/</g, " ").trim();
    }

    private extractDocumentCode(code: string): string {
        console.log("Extracted Doc Code: ", code);
        if (this.DOCUMENT_CODE_REGEX.test(code.trim())) {
            return code;
        }
        throw new Error(this.translateService.instant("invalid_document_code"));
    }

    private extractCountryCode(code: string): string {
        if (this.COUNTRY_CODE_REGEX.test(code.trim())) {
            return code;
        }
        throw new Error(this.translateService.instant("invalid_country_code"));
    }

    private extractDate(date: string, isExpiry: boolean = false): string {
        const year = date.slice(0, 2);
        const month = date.slice(2, 4);
        const day = date.slice(4, 6);

        let fullYear = parseInt(year) > 50 ? `19${year}` : `20${year}`; // Assuming 50 as a cutoff for 1900s/2000s        
        if (isExpiry) {
            fullYear = `20${year}`;
        }
        return `${day}/${month}/${fullYear}`;
    }

    private extractSex(sex: string): string {
        if (sex === "M" || sex === "F") {
            return sex;
        }
        return "X"; // Unspecified
    }
}
