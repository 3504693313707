import { Injectable } from "@angular/core";
import { Data } from "../modules/facetec/dto";

@Injectable({
    providedIn: "root",
})
export class DataService {
    data: Data | null = null;

    setData(data: Data) {
        this.data = data;
    }

    getData(): Data | null {
        return this.data;
    }
}
