import { Injectable } from "@angular/core";
import { Config, Error } from "../modules/facetec/dto";
import { safeStringify } from "ajv/dist/compile/codegen/code";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private readonly STORAGE_KEYS = {
    CONFIG: 'facetec_config',
    TRY_AGAIN: 'facetec_tryAgain',
    RECEIVED_ERRORS: 'facetec_receivedErrors',
    LAST_ERROR: 'facetec_lastError',
    ERROR_COUNT: 'facetec_errorCount',
    ENROLLMENT_IDENTIFIER: 'facetec_enrollmentIdentifier',
    RETRY_COUNTER: 'facetec_retryCounter',
    NEXT_STEP: 'facetec_nextStep',
    LANGUAGE: 'facetec_language'
  };

  getLanguage(): string {
    return sessionStorage.getItem(this.STORAGE_KEYS.LANGUAGE) || 'en';
  }

  setLanguage(language: string): void {
    sessionStorage.setItem(this.STORAGE_KEYS.LANGUAGE, language);
  }

  getNextStep(): number {
    return Number(sessionStorage.getItem(this.STORAGE_KEYS.NEXT_STEP)) || 0;
  }

  setNextStep(ns: number): void {
    sessionStorage.setItem(this.STORAGE_KEYS.NEXT_STEP, ns.toString());
  }

  setRetryCounter(count: number): void {
    sessionStorage.setItem(this.STORAGE_KEYS.RETRY_COUNTER, count.toString());
  }

  getRetryCounter(): number {
    return Number(sessionStorage.getItem(this.STORAGE_KEYS.RETRY_COUNTER)) || 0;
  }

  setErrorCount(errorCount: number): void {
    sessionStorage.setItem(this.STORAGE_KEYS.ERROR_COUNT, errorCount.toString());
  }

  getErrorCount(): number {
    return Number(sessionStorage.getItem(this.STORAGE_KEYS.ERROR_COUNT)) || 0;
  }

  getEnrollmentIdentifier(): string | null {
    return sessionStorage.getItem(this.STORAGE_KEYS.ENROLLMENT_IDENTIFIER);
  }

  setEnrollmentIdentifier(enrollmentIdentifier: string): void {
    sessionStorage.setItem(this.STORAGE_KEYS.ENROLLMENT_IDENTIFIER, enrollmentIdentifier);
  }

  getLastError(): Error | null {
    const error = sessionStorage.getItem(this.STORAGE_KEYS.LAST_ERROR);
    return error ? JSON.parse(error) : null;
  }


  setLastError(lastError: Error): void {
    sessionStorage.setItem(this.STORAGE_KEYS.LAST_ERROR, safeStringify(lastError));
  }

  setConfig(config: Config): void {
    sessionStorage.setItem(this.STORAGE_KEYS.CONFIG, safeStringify(config));
  }

  getConfig(): Config | null {
    const config = sessionStorage.getItem(this.STORAGE_KEYS.CONFIG);
    return config ? JSON.parse(config) : null;
  }

  getTryAgain(): boolean {
    return sessionStorage.getItem(this.STORAGE_KEYS.TRY_AGAIN) === 'true';
  }

  setTryAgain(tryAgain: boolean): void {
    sessionStorage.setItem(this.STORAGE_KEYS.TRY_AGAIN, tryAgain.toString());
  }

  getReceivedErrors(): Error[] {
    const errors = sessionStorage.getItem(this.STORAGE_KEYS.RECEIVED_ERRORS);
    return errors ? JSON.parse(errors) : [];
  }

  setRetrievedErrors(receivedErrors: Error[]): void {
    sessionStorage.setItem(this.STORAGE_KEYS.RECEIVED_ERRORS, safeStringify(receivedErrors));
  }

  // Optional: Method to clear all stored data
  clearAll(): void {
    Object.values(this.STORAGE_KEYS).forEach(key => {
      sessionStorage.removeItem(key);
    });
  }
}