import { Injectable } from '@angular/core';
import { SlackService } from "./slack.service";

@Injectable({
    providedIn: 'root'
})
export class CustomLoggerService {
    constructor(private slackService: SlackService) {
    }
    
    log(message: any, ...optionalParams: any[]): void {
        // Your custom implementation
        const timestamp = new Date().toISOString();
        try {
            console.info(`[${timestamp}] LOG:`, message, ...optionalParams);
        } catch (error) {
            console.info('Error while logging error:');
        }
        this.slackService.sendMessage({ message, optionalParams });
    }

    error(message: any, ...optionalParams: any[]): void {
        // Custom error implementation
        const timestamp = new Date().toISOString();
        try {
            console.info(`[${timestamp}] ERROR:`, message, ...optionalParams);
        } catch (error) {
            console.info('Error while logging error:');
        }
        this.slackService.sendMessage({ message, optionalParams });
    }

    warn(message: any, ...optionalParams: any[]): void {
        // Custom warning implementation
        const timestamp = new Date().toISOString();
        try {
            console.info(`[${timestamp}] WARN:`, message, ...optionalParams);
        } catch (error) {
            console.info('Error while logging error:');
        }
        this.slackService.sendMessage({ message, optionalParams });
    }

    info(message: any, ...optionalParams: any[]): void {
        // Custom info implementation
        const timestamp = new Date().toISOString();
        console.info(`[${timestamp}] INFO:`, message, ...optionalParams);
        this.slackService.sendMessage({ message, optionalParams });
    }
}