import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	constructor(private translateService: TranslateService) {
		this.translateService.addLangs(["ar", "en"]);
		this.translateService.setDefaultLang("ar");

		const browserLang = translateService.getBrowserLang();
		console.log("We got browser language: ", browserLang);
		this.translateService.use(browserLang.match(/en|ar/) ? browserLang : "ar");
	}
	ngOnInit() {
		this.translateService.onLangChange.subscribe(event => {
			document.documentElement.dir = this.isRTLLanguage(event.lang) ? 'rtl' : 'ltr';
		});
	}

	ngOnDestroy(): void {
		this.translateService.onLangChange.unsubscribe();
	}

	private isRTLLanguage(lang: string): boolean {
		const rtlLanguages = ['ar', 'he', 'fa', 'ur'];
		return rtlLanguages.includes(lang);
	}
}
