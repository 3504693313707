<div class="light-grey">
	<div class="row">
		<div class="col text-center title">{{'authentication' | translate}}</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-0 bg-white shadow-sm border-white rounded">
				<div class="card-body p-0 pb-2">
					<div class="block-background">
						<div class="block-info">
							<div class="row">
								<div class="col">
									<div class="card m-3 bg-white rounded">
										<div class="card-body">
											<div class="row">
												<div class="col-2"><img src="assets/images/portrait.png" height="30px">
												</div>
												<div class="col">
													<div class="row">
														<div class="col text-start title">{{'step' | translate}}
															{{'one' | translate}}: {{'take_your_photo' | translate}}
														</div>
													</div>
												</div>
												<div class="col-2"><img src="assets/images/done.png" height="20px">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<div class="card m-3 bg-white rounded">
										<div class="card-body">
											<div class="row">
												<div class="col-2"><img src="assets/images/document.png" height="30px">
												</div>
												<div class="col">
													<div class="row">
														<div class="col text-start title">{{'step' | translate}}
															{{'two' | translate}}: {{'scan_your_document' |
															translate}}
														</div>
													</div>
												</div>
												<div class="col-2"><img src="assets/images/done.png" height="20px">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<div class="card m-3 bg-white rounded">
										<div class="card-body">
											<div class="row">
												<div class="col-2"><img src="assets/images/review.png" height="30px">
												</div>
												<div class="col">
													<div class="row">
														<div class="col text-start title">{{'step' | translate}}
															{{'three' | translate}}: {{'review_information'
															|translate}}
														</div>
													</div>
												</div>
												<div class="col-2"><img src="assets/images/done.png" height="20px">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<div class="card m-3 bg-white rounded">
										<div class="card-body">
											<div class="row">
												<div class="col-2"><img src="assets/images/signature_red.png"
														height="30px"></div>
												<div class="col">
													<div class="row">
														<div class="col text-start title">{{'step' | translate}}
															{{'four' | translate}}:
															{{'add_your_signature' | translate}}</div>
													</div>
												</div>
												<div class="col-2"><img src="assets/images/done.png" height="20px">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row mt-2">
								<div class="d-grid gap-2 col-6 mx-auto space-after grid-button">
									<button (click)="submit()"
										class="btn bg-gradient btn-zain btn-zain-next">{{'submit' | translate}}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>