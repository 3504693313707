<div class="light-grey">
	<div class="row">
		<div class="col text-center title">{{'authentication' | translate}}</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-0 bg-white shadow-sm border-white rounded">
				<div class="card-body p-0 pb-2">
					<div class="block-background">
						<div class="block-info">
							<div class="row mt-4">
								<div class="col"><img src="assets/images/portrait.png" height="30px"></div>
							</div>
							<div class="row mt-3">
								<div class="col step-title">{{'step' | translate}} {{'one' | translate}}/{{'four' | translate}}</div>
							</div>
							<div class="row mt-2">
								<div class="col step-title">{{'take_your_photo' | translate}}</div>
							</div>
							<div class="row mt-2">
								<div class="col step-info">{{'typ_instruction' | translate}}</div>
							</div>
							<div class="row mt-3">
								<div class="d-grid gap-2 col-6 mx-auto space-after grid-button">
									<button (click)="proceed()" class="btn bg-gradient btn-zain btn-zain-next">{{'proceed' | translate}}</button>
								</div>
							</div>
							<div class="row mt-1">
								<div class="d-grid gap-2 col-6 mx-auto space-after grid-button">
									<button class="btn btn-zain" (click)="cancel()">{{'cancel' | translate}}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
