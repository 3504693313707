import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Config, Data } from "../../../dto";
import { RefService } from "src/app/service/ref.service";
import { ConfigService } from "src/app/service/config.service";
import { DataService } from "src/app/service/data.service";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import { trackJourney } from "src/app/utils/utils";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
    selector: "app-review",
    templateUrl: "./review.component.html",
    styleUrls: ["./review.component.scss"],
})
export class ReviewComponent implements OnInit, OnDestroy {
    ref: string;
    config: Config;
    data: Data;

    reviewData = [];

    titles = {
        fullName: "Full Name",
        dateOfBirth: "Date of Birth",
        nationality: "Nationality",
        idNumber: "ID Number",
        dateOfExpiration: "Date of Expiry",
    }

    direction;

    private langChangeSubscription: Subscription;

    constructor(
        private router: Router,
        private refService: RefService,
        private configService: ConfigService,
        private dataService: DataService,
        private httpService: HttpFacetecService,
        private translateService: TranslateService,
        private zone: NgZone,
    ) {
        this.direction = this.translateService.currentLang === 'ar' ? 'rtl' : 'ltr';
    }

    ngOnInit() {
        this.ref = this.refService.getRef();
        this.config = this.configService.getConfig() as Config;
        this.data = this.dataService.getData();
        // this.data =
        //     {
        //         fullName: "Akintayo Olusegun",
        //         dateOfBirth: "12/12/1999",
        //         nationality: "Nigerian",
        //         idNumber: "1234567890",
        //         dateOfExpiration: "12/12/2022",
        //     } as Data;

        trackJourney({ ref: this.refService.getRef(), page: "review_start", httpService: this.httpService });
        this.getTitles();
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(event => {
            this.direction = event.lang === 'ar' ? 'rtl' : 'ltr';
            this.getTitles();
        });

        const lang = this.configService.getLanguage() || 'en';
        this.translateService.use(lang);
    }

    ngOnDestroy() {
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    proceed() {
        trackJourney({ ref: this.refService.getRef(), page: "review_success", httpService: this.httpService });
        this.router.navigate(["/sign"]);
    }

    cancel() {
        this.configService.setTryAgain(true);
        this.router.navigate(["/facetec"]);
    }

    async getTitles() {
        const keys = Object.keys(this.titles);
        for (let key of keys) {
            const translateKey = `review.${key}`;

            const res = await this.translateService.get(translateKey).toPromise();
            let message = res;
            if (res && res.length > 0) {
                this.titles[key] = message;
            }
        }

        console.log(this.titles);

        this.reviewData = [
            {
                id: "fullName",
                title: this.titles.fullName,
                value: this.data.fullName
                    ? this.data.fullName
                    : `${this.data.lastName} ${this.data.firstName}`,
            },
            {
                id: "dateOfBirth",
                title: this.titles.dateOfBirth,
                value: this.data.dateOfBirth,
            },
            {
                id: "nationality",
                title: this.titles.nationality,
                value: this.data.nationality || this.data.countryCode,
            },
            {
                id: "idNumber",
                title: this.titles.idNumber,
                value: this.data.idNumber,
            },
            {
                id: "dateOfExpiration",
                title: this.titles.dateOfExpiration,
                value: this.data.dateOfExpiration,
            },
        ];
    }
}
