import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConfigService } from "src/app/service/config.service";
import packageInfo from "../../../../../../../package.json";
import { Config, Error, ErrorType } from "../../../dto";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import { RefService } from "src/app/service/ref.service";
import { catchError } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { DataService } from "src/app/service/data.service";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-eroor",
    templateUrl: "./error.component.html",
    styleUrls: ["./error.component.scss"],
})
export class ErrorComponent implements OnInit, OnDestroy {
    public version = packageInfo.version;

    config: Config;
    errors: Error[];
    errorCount = 0;
    lastError: Error;
    receivedErrors: Error[];

    @ViewChild("top") top: ElementRef;

    constructor(
        private configService: ConfigService,
        private dataService: DataService,
        private router: Router,
        private httpService: HttpFacetecService,
        private refService: RefService,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private translateService: TranslateService,
        private zone: NgZone,
    ) {}

    ngOnInit(): void {
        console.log("Facetec::ngOnInit");
        this.config = this.configService.getConfig() as Config;
        this.receivedErrors = this.configService.getReceivedErrors() as Error[];
        this.errorCount = this.configService.getErrorCount();
        this.lastError = this.configService.getLastError() as Error;

        const data = this.dataService.getData();
        if (!this.lastError && this.receivedErrors && this.receivedErrors.length > 0) {
            this.lastError = this.receivedErrors[this.receivedErrors.length - 1];
        }

        this.lastError.data = data;

        if (this.receivedErrors) {
            this.errors = this.receivedErrors;
        }

        const lang = this.configService.getLanguage() || 'en';
        this.translateService.use(lang);
    }

    tryAgain() {
        this.configService.setTryAgain(true);
        this.router.navigate(["/facetec"]);
    }

    skip() {
        this.spinner.show();
        const ref = this.refService.getRef();
        
        this.httpService
            .processError(ref, this.lastError)
            .pipe(
                catchError((error) => {
                    this.spinner.hide();
                    console.log("Error submitting process error data", error);
                    this.toastr.error((error.statusText ? error.statusText : error.message), 'Error');
                    throw error;
                }),
            )
            .subscribe((result) => {
                this.spinner.hide();
                if (result.redirectUrl) {
                    this.zone.runOutsideAngular(() => {
                        setTimeout(function () {
                            document.location.href = result.redirectUrl;
                        }, 100);
                    });
                } else {
                    this.tryAgain();
                }
            });
    }

    ngOnDestroy(): void {}

    get lastErrorDescription(): string {
        console.log("Facetec::lastErrorDescription :: ", this.lastError.errorType);
        switch (this.lastError.errorType) {
            case ErrorType.IdSpoofDetected: {
                return "Inconclusive if its a Physical card or a digital image.";
            }
            case ErrorType.FaceDidNotMatchId: {
                return "The face captured did not match the face on the ID document or the data on front and back of ID Card did not match";
            }
            case ErrorType.FaceLivenessFailed: {
                return "The face captured did not appear to be a live face.";
            }
            case ErrorType.IdScanFailed: {
                return "The ID scan failed. Please ensure you hold the camera steady and their is sufficient light to read your ID.";
            }
            case ErrorType.IdTypeNotAllowed: {
                return (
                    "The ID type " +
                    this.lastError.data.ocrTemplateName +
                    " used is not allowed. Please use a valid and allowed ID type."
                );
            }
            case ErrorType.IdTypeUnexpectedMedia: {
                return "The ID type did not appear to be a physical and / or legitimate document.";
            }
            case ErrorType.OcrTemplateMatchFailed: {
                return "The ID extraction failed as the ID type was not recognized.";
            }
            case ErrorType.AxonMismatchInformation: {            
                return "error_axon_mismatch_information";
            }
            case ErrorType.AxonMissingData: {
                return "Missing Data: " + this.dataService.getData().missingData;
            }
        }
    }
}
