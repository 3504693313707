import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RefService {
  ref: string | null = null;

  setRef(ref: string) {
    this.ref = ref;
  }

  getRef(): string | null {
    return this.ref;
  }
}