import { Component, NgZone } from "@angular/core";
import { Config, Error } from "../../../dto";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import { catchError } from "rxjs/operators";
import { RefService } from "src/app/service/ref.service";
import { ConfigService } from "src/app/service/config.service";
import { DataService } from "src/app/service/data.service";

@Component({
	selector: 'app-skip-capture',
	templateUrl: './skip-capture.component.html',
	styleUrls: ['./skip-capture.component.scss']
})
export class SkipCaptureComponent {

	ref: string;
	config: Config;
	errors: Error[];
	lastError: Error;

	constructor(
		private router: Router, 
		private httpService: HttpFacetecService, 
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		private zone: NgZone,
        private refService: RefService,
        private configService: ConfigService,
	) {
		this.ref = this.refService.getRef();
		this.config = this.configService.getConfig() as Config;
		this.errors = this.configService.getReceivedErrors() as Error[];
		this.lastError = this.configService.getLastError() as Error;
	}

	tryAgain() {
		this.configService.setTryAgain(true);
		this.zone.run(() => {
			this.router.navigate(['/facetec']);
		});
	}
	skip() {		
		if (this.errors.filter(item => item.submitted === false).length === 0) {
			this.doProcessErrorIfAllErrorsSubmitted();
			return;
		}

		this.spinner.show();
		this.errors.filter(item => item.submitted === false)
			.forEach(error => {
				this.httpService.audit(this.ref, {
					errorType: error.errorType,
					data: error.data
				})
				.pipe(
					catchError(error => {
						this.spinner.hide();
						console.log('Error submitting audit data', error);
						this.toastr.error((error.statusText ? error.statusText : error.message), 'Error');
						throw error;
					})
				)
				.subscribe(result => {
					this.spinner.hide();

					error.submitted = true;
		
					this.doProcessErrorIfAllErrorsSubmitted();
				});
			});
	}

	private doProcessErrorIfAllErrorsSubmitted() {
		var unsubmitted = this.errors.filter(item => item.submitted === false);
		if (unsubmitted.length > 0) {
			this.toastr.error('Unable to submit request to continue. Please try again.', 'Error');
			return;
		}

		this.spinner.show();
		this.httpService.processError(this.ref, this.lastError)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error submitting process error data', error);
				this.toastr.error((error.statusText ? error.statusText : error.message), 'Error');
				throw error;
			})
		)
		.subscribe(result => {
			this.spinner.hide();

			if (result.redirectUrl) {
				this.zone.runOutsideAngular(() => {
					setTimeout(function() {
						document.location.href = result.redirectUrl;
					}, 1000);
				});
			}
		});
	}
}